import { StyledP } from '@/Shared/Typography/typography';

interface CourseUnitsPlaceholderProps {
	isApprovalMode: boolean;
	coursesDraftFocuses: CourseFocusEntity[];
}

const CourseUnitsPlaceholder = ({
	isApprovalMode,
	coursesDraftFocuses,
}: CourseUnitsPlaceholderProps) => {
	const renderText = () => {
		let text = '';

		if (
			(isApprovalMode && coursesDraftFocuses) ||
			(isApprovalMode && coursesDraftFocuses && coursesDraftFocuses?.length === 0)
		) {
			text = 'No units of focus are present.';
		}

		if (!coursesDraftFocuses || (coursesDraftFocuses && coursesDraftFocuses?.length === 0)) {
			text = 'Add the first unit of focus.';
		}

		return text;
	};

	if (isApprovalMode) {
		return null;
	}

	if (!renderText()) {
		return null;
	}

	return <StyledP mb="32px">{renderText()}</StyledP>;
};

export default CourseUnitsPlaceholder;
