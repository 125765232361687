import useConfirmationDialog from '@/hooks/useConfirmationModal';
import { TrashCanIcon } from '@/icons';
import { useCourseLessonsStore } from '@/stores/courseEditStore';

interface LessonPlanEntryProps {
	lesson: Lesson;
	index: number;
	isActive: boolean;
	groupId: string;
}

const LessonPlanEntry = ({ lesson, index, isActive, groupId }: LessonPlanEntryProps) => {
	const { removeLesson, setIndex, currentIndex } = useCourseLessonsStore();
	const activeClass = isActive && index === currentIndex ? 'lesson-plan-item-active' : '';
	const confirm = useConfirmationDialog({
		message: 'Are you sure you want to delete this lesson?',
		onConfirm: () => removeLesson(groupId, lesson.id), // TODO: fix...this causes a memory leak
		onCancel: () => null,
	});

	return (
		<>
			{confirm.Dialog()}
			<div className="lesson-plan-item">
				<button
					type="button"
					className={`lesson-plan-button ${activeClass}`}
					onClick={() => setIndex(index)}>
					{lesson.title || 'New Lesson'}
				</button>
				<TrashCanIcon passedEvent={() => confirm.onOpen(lesson.id)} />
			</div>
		</>
	);
};

export default LessonPlanEntry;
