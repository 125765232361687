import React, { useContext, useEffect, useState } from 'react';
import debounce from 'just-debounce-it';

import { EditorType } from '@/Enums/enum';
import { StyledH5 } from '@/Shared/Typography/typography';
import { StyledInput } from '@/Shared/StyledElements';
import TiptapEditor from '@/components/TipTapEditor/TiptapEditor';
import { useCourseEditStore } from '@/stores/courseEditStore';
import FadeIn from '@/components/animations/FadeIn';
import useSaveDraft from '@/hooks/useSaveDraft';
import CourseEditContext from '../CourseEditContext';
import CourseDiff from '../CourseDiff';
import ApprovalCommentForm from './ApprovalCommentForm';

const CourseDescriptionTab = () => {
	const {
		saveDraft,
		canEdit,
		courseData,
		courseDraftData,
		isApprovalMode,
		updatePrereq,
		updateCourseReducer,
	} = useContext(CourseEditContext);

	const { updateCommentTypes, dynamicDoc, docTypes, dynamicProvider, providers } =
		useCourseEditStore();

	const {
		course_description: courseDescription,
		course_extras: courseExtras,
		course_prereq: coursePrereq,
		course_id: courseId,
	} = courseData;

	const {
		course_description: courseDraftDescription,
		course_extras: courseDraftExtras,
		course_prereq: courseDraftPrereq,
	} = courseDraftData;

	const [isCommented, setIsCommented] = useState(false);
	// once a comment is added, state is updated and the draft is saved
	const saveAfterCommenting = (value: string, type: EditorType) => {
		updateCourseReducer(value, type);
		setIsCommented(!isCommented);
	};
	// once a comment is added, save a draft using hook
	useSaveDraft(isCommented, saveDraft, setIsCommented);

	const onDescriptionChange = debounce((value: string) => {
		updateCourseReducer(value, EditorType.DESCRIPTION);
	}, 500);

	const onPrereqChange = debounce((value: string) => {
		updatePrereq(value);
	}, 500);

	const onAdditionalInfoChange = debounce((value: string) => {
		updateCourseReducer(value, EditorType.EXTRAINFO);
	}, 500);

	useEffect(() => {
		updateCommentTypes([EditorType.DESCRIPTION, EditorType.EXTRAINFO]);
		dynamicDoc(courseData.course_id, EditorType.DESCRIPTION);
		dynamicProvider(courseData.course_id, EditorType.DESCRIPTION);
		dynamicDoc(courseData.course_id, EditorType.EXTRAINFO);
		dynamicProvider(courseData.course_id, EditorType.EXTRAINFO);
	}, [updateCommentTypes, dynamicDoc, courseData.course_id, dynamicProvider]);

	return (
		<>
			<FadeIn style={{ width: '100%' }}>
				<div>
					{isApprovalMode && (
						<ApprovalCommentForm
							courseId={courseData.course_id}
							type={EditorType.DESCRIPTION}
						/>
					)}
					{!isApprovalMode &&
						docTypes[`${courseData.course_id}${EditorType.DESCRIPTION}`] && (
							<TiptapEditor
								content={courseDraftDescription}
								onChange={onDescriptionChange}
								placeholder="Add course description..."
								type={EditorType.DESCRIPTION}
								isEditable={canEdit}
								commentAddedCallback={(value) =>
									saveAfterCommenting(value, EditorType.DESCRIPTION)
								}
								id={courseId}
								providerRef={providers[`${courseId}${EditorType.DESCRIPTION}`]}
								documentRef={docTypes[`${courseId}${EditorType.DESCRIPTION}`]}
							/>
						)}
					{isApprovalMode && (
						<CourseDiff
							title="Course Description"
							original={courseDescription}
							draft={courseDraftDescription}
						/>
					)}
					<StyledH5 mt="32px" mb="8px">
						Recommended Prerequisites
					</StyledH5>
					{!isApprovalMode && (
						<StyledInput
							type="text"
							defaultValue={courseDraftPrereq}
							placeholder="Course Prerequisites"
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								onPrereqChange(e.currentTarget.value);
							}}
						/>
					)}
					<CourseDiff original={coursePrereq} draft={courseDraftPrereq} />
					<StyledH5 mt="32px" mb="8px">
						Additional Information
					</StyledH5>
					{!isApprovalMode &&
						docTypes[`${courseData.course_id}${EditorType.EXTRAINFO}`] && (
							<TiptapEditor
								content={courseDraftExtras}
								onChange={onAdditionalInfoChange}
								placeholder="Add course additional information..."
								type={EditorType.EXTRAINFO}
								isEditable={canEdit}
								commentAddedCallback={(value) =>
									saveAfterCommenting(value, EditorType.EXTRAINFO)
								}
								id={courseData.course_id}
								providerRef={providers[`${courseId}${EditorType.EXTRAINFO}`]}
								documentRef={docTypes[`${courseId}${EditorType.EXTRAINFO}`]}
							/>
						)}
					{isApprovalMode && (
						<CourseDiff original={courseExtras} draft={courseDraftExtras} />
					)}
				</div>
			</FadeIn>
		</>
	);
};

export default CourseDescriptionTab;
