import { useQueries } from '@tanstack/react-query';
import Loader from '@/components/Loader';
import { StyledSubHeader, StyledJumboText } from '@/Shared/Typography/typography';
import { capitalize } from '@/utils';
import { getCourseCount, getUnassignedCourses, getUserCount, getUnassignedUsers } from './Queries';
import { StyledCard, StyledCardDeck } from './Stats.styles';

const Stats = () => {
	const results = useQueries({
		queries: [
			{
				queryKey: ['course-count-aggregate'],
				queryFn: () => getCourseCount(),
				staleTime: Infinity,
				refetchOnMount: 'always',
			},
			{
				queryKey: ['unassigned-course-count'],
				queryFn: () => getUnassignedCourses(),
				staleTime: Infinity,
				refetchOnMount: 'always',
			},
			{
				queryKey: ['user-count'],
				queryFn: () => getUserCount(),
				staleTime: Infinity,
				refetchOnMount: 'always',
			},
			{
				queryKey: ['unassigned-users'],
				queryFn: () => getUnassignedUsers(),
				staleTime: Infinity,
				refetchOnMount: 'always',
			},
		],
	});

	const courseCount = results[0].data;
	const unassignedCourses = results[1].data;
	const userCount = results[2].data;
	const unassignedUsers = results[3].data;
	const isLoading = results.some((result) => result.isLoading);

	if (isLoading) return <Loader />;

	return (
		<StyledCardDeck style={{ height: '100%' }}>
			<StyledCard>
				<StyledSubHeader className="text-center" fontSize="20px">
					Unassigned Courses
				</StyledSubHeader>
				<StyledJumboText fontSize="120px" mb="0" className="text-center">
					{unassignedCourses?.count}
				</StyledJumboText>
			</StyledCard>
			<StyledCard>
				<StyledSubHeader className="text-center" fontSize="20px">
					Total Users
				</StyledSubHeader>
				<StyledJumboText fontSize="120px" mb="0" className="text-center">
					{userCount?.count}
				</StyledJumboText>
			</StyledCard>
			<StyledCard>
				<StyledSubHeader className="text-center" fontSize="20px">
					Total Courses
				</StyledSubHeader>
				<StyledJumboText fontSize="120px" mb="0" className="text-center">
					{courseCount?.count}
				</StyledJumboText>
			</StyledCard>
			<StyledCard className="truncated-height">
				<StyledSubHeader className="text-center" fontSize="20px" mb="8px">
					Unmapped Courses
				</StyledSubHeader>
				<small>Courses not represented within the course map.</small>
				<ol>
					{courseCount?.unusedCourses.map((course) => (
						<li key={course.course_id}>{course.course_name}</li>
					))}
				</ol>
			</StyledCard>
			<StyledCard className="truncated-height">
				<StyledSubHeader className="text-center" fontSize="20px">
					Unassigned Users
				</StyledSubHeader>
				<ol>
					{unassignedUsers?.users.map((user) => (
						<li key={user.user_id}>
							{user.user_first} {user.user_last} - (Role: {capitalize(user.user_role)}
							)
						</li>
					))}
				</ol>
			</StyledCard>
		</StyledCardDeck>
	);
};

export default Stats;
