import { useEffect, useState } from 'react';
import { rebuildTooltip } from '@/components/TaggedWordTooltip/TaggedWordTooltip';

interface TabProps {
	selected: number;
	children: JSX.Element[];
}

export const Tabs = ({ selected, children }: TabProps) => {
	const [currentIdx, setCurrentIdx] = useState(selected);
	useEffect(() => {
		rebuildTooltip();
	}, [currentIdx]);

	const handleChange = (index: number) => {
		setCurrentIdx(index);
	};

	return (
		<>
			<div className="tab-group">
				{children.map((elm: JSX.Element, index: number) => {
					const isActive = index === currentIdx;
					return (
						<button
							className={isActive ? 'active' : ''}
							type="button"
							key={`${index.toString()}`}
							onClick={() => handleChange(index)}>
							{elm.props.title}
						</button>
					);
				})}
			</div>
			<div className="tab-content">{children[currentIdx]}</div>
		</>
	);
};

interface PanelProps {
	title: string;
	children: JSX.Element;
}

export const Panel = ({ ...props }: PanelProps) => {
	const { children } = props;
	return children;
};
