/* eslint-disable react/no-unstable-nested-components */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Select from 'react-select';

import { StyledPrimaryButton } from '@/Shared/StyledElements';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import { themeSelectStyles } from '@/utils';
import FadeIn from '@/components/animations/FadeIn';
import TableFilters from '@/components/TableFilters/TableFilters';
import Table from '@/components/Table';
import Loader from '@/components/Loader';
import { EditIcon, TrashCanIcon } from '@/icons/index';
import { GetAllDivisionsQuery } from '@/graphql/graphql';
import { ConfirmationWarning } from './ConfirmationWarning';
import { StyledActionsContainer, StyledUtilsContainer } from './DivisionsListStyles';
import DivisionsModal from './DivisionsModal';
import { useGetDivisions, deleteDivision, updateCourseDivision } from './DivisionsContainers';

const Divisions = () => {
	const [modalData, setModalData] = useState({
		isActive: false,
		isEditing: false,
		division: {} as GetAllDivisionsQuery['dev_divisions'][0],
	});
	const { data: divisions, isFetching, isLoading } = useGetDivisions();
	const [filteredData, setFilteredData] = useState([]);
	const [isConfirming, setIsConfirming] = useState(false);
	const [isValidated, setIsValidated] = useState(true);
	const [newDivision, setNewDivision] = useState({
		id: '',
		name: '',
	});
	const queryClient = useQueryClient();

	const handleSetData = useCallback((queryDivisionData: any) => {
		setFilteredData(queryDivisionData);
	}, []);

	const { mutate: deleteDivisionMutation } = useMutation({
		mutationFn: deleteDivision,
		onSuccess: (_, deletedValue) => {
			showSuccessToast('Division Deleted');

			// update query cache
			const previousDivisionData = queryClient.getQueryData([
				'get-all-divisions',
			]) as Division[];
			const updatedDivisions = previousDivisionData.filter(
				(division: Division) => division.id !== deletedValue.id
			);
			queryClient.setQueryData(['get-all-divisions'], updatedDivisions);
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const { mutate: courseDivisionMutation } = useMutation({
		mutationFn: updateCourseDivision as any,
		onSuccess: (courseDivisions: CoursesDivisionEntity[], idObject: any) => {
			const { previousDivision, newDivision } = idObject;
			showSuccessToast('Divisions and Courses Updated');
			// update query cache
			const previousDivisionData = queryClient.getQueryData([
				'get-all-divisions',
			]) as Division[];
			// need to clone data in order to not mutate the cache
			const updatedCache = [
				...previousDivisionData.filter(
					(division: Division) => division.id !== previousDivision.id
				),
			];
			const idx = updatedCache.findIndex(
				(division: Division) => division.id === newDivision.id
			);
			updatedCache[idx].courses_divisions = courseDivisions;
			queryClient.setQueryData(['get-all-divisions'], updatedCache);
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const isAssigned = useCallback(
		(division: GetAllDivisionsQuery['dev_divisions'][0]) =>
			Boolean(division.courses_divisions && division?.courses_divisions?.length >= 1),
		[]
	);

	const removeDivision = (division: GetAllDivisionsQuery['dev_divisions'][0] | undefined) => {
		if (!division) return;

		if (!isAssigned(division)) {
			deleteDivisionMutation({
				id: division.id,
			});
			return;
		}

		courseDivisionMutation({
			newDivision,
			previousDivision: division,
		});
	};

	const confirmDelete = useCallback(
		(division: GetAllDivisionsQuery['dev_divisions'][0]) => {
			setIsValidated(!isAssigned(division));
			setModalData({ isActive: false, isEditing: true, division });
			setIsConfirming(true);
		},
		[isAssigned]
	);

	const getOptionList = () => {
		const filteredDivisions =
			divisions?.filter((division) => division.id !== modalData.division.id) ?? [];

		const options = filteredDivisions.map((division) => ({
			value: division.name,
			label: division.name,
			id: division.id,
		}));

		return options;
	};

	useEffect(() => {
		const abortController = new AbortController();
		return () => {
			abortController.abort();
		};
	}, [divisions]);

	const queryData = useMemo(
		() =>
			divisions?.map((division) => ({
				name: division.name,
				numberOfCourses: division.courses_divisions?.length ?? 0,
				actions: division,
				idColumn: division.id,
			})),
		[divisions]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				sortType: 'basic',
			},
			{
				Header: 'Assigned Courses',
				accessor: 'numberOfCourses',
				alignment: 'center',
				Cell(cellData: any) {
					const { value } = cellData;
					return <div style={{ textAlign: 'center' }}>{value}</div>;
				},
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				alignment: 'center',
				Cell(cellData: any) {
					const { rowData } = cellData;
					const updateDivisionModal = () => {
						setModalData({
							isActive: true,
							isEditing: true,
							division: rowData.actions,
						});
					};

					return (
						<StyledActionsContainer>
							<EditIcon width={18} passedEvent={updateDivisionModal} />
							<TrashCanIcon
								passedEvent={() => {
									confirmDelete(rowData.actions);
								}}
							/>
						</StyledActionsContainer>
					);
				},
			},
		],
		[confirmDelete]
	);

	if (isLoading) return <Loader />;
	return (
		<>
			{isConfirming && (
				<ConfirmModal
					modalActive={isConfirming}
					onConfirm={() => removeDivision(modalData.division)}
					onCancel={() => setIsConfirming(false)}
					triggerModal={() => setIsConfirming(false)}
					message="Are you sure you want to delete the following division?"
					selectedData={modalData.division?.name ?? ''}
					validated={isValidated}>
					{isAssigned(modalData.division) && (
						<>
							<ConfirmationWarning division={modalData.division} />
							<Select
								data-testid="filterSelect"
								styles={themeSelectStyles}
								placeholder="Select a new division"
								onChange={(option) => {
									setNewDivision({
										id: `${option?.id}`,
										name: `${option?.label}`,
									});
									setIsValidated(true);
								}}
								isClearable
								options={[...getOptionList()]}
								noOptionsMessage={() => 'No Divisions Found'}
							/>
						</>
					)}
				</ConfirmModal>
			)}
			{modalData.isActive && (
				<DivisionsModal
					modalData={modalData}
					allDivisions={divisions}
					triggerModal={() =>
						setModalData({
							isActive: false,
							isEditing: false,
							division: {} as GetAllDivisionsQuery['dev_divisions'][0],
						})
					}
				/>
			)}
			<StyledUtilsContainer>
				<StyledPrimaryButton
					size="small"
					onClick={() => {
						setModalData({
							isActive: true,
							isEditing: false,
							division: {} as GetAllDivisionsQuery['dev_divisions'][0],
						});
					}}>
					Add New Division
				</StyledPrimaryButton>
				{queryData && (
					<TableFilters
						data={queryData}
						handleSetData={handleSetData}
						searchColumn={['name']}
						filteredColumn="name"
						resultQty={filteredData.length}
						dropdown={false}
					/>
				)}
			</StyledUtilsContainer>
			{!isFetching && (
				<FadeIn style={{ height: '100%' }}>
					<Table data={filteredData} columns={columns} />
				</FadeIn>
			)}
		</>
	);
};

export default Divisions;
