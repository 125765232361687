import { useContext, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';

import {
	StyledPrimaryButton,
	StyledSecondaryButton,
	StyledTextArea,
} from '@/Shared/StyledElements';
import GlobalContext from '@/context/GlobalContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from '@/components/ToastNotification';
import { FormErrorMessage } from '@/components/FormErrorMessage';
import { prefix } from '@/utils';

const StyledCommentFormContainer = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: var(--spacing-5);

	form {
		display: grid;
		grid-template-areas:
			'text text text'
			' - submit cancel';
		grid-template-columns: 1fr auto auto;
		grid-template-rows: auto auto;
		grid-row-gap: var(--spacing-3);
		grid-column-gap: var(--spacing-3);
		width: 100%;
	}

	${StyledPrimaryButton},
	${StyledSecondaryButton} {
		font-size: 1.4rem;
		padding: 4px var(--spacing-3);
	}

	textarea {
		grid-area: text;
		resize: vertical;
	}

	.error-message {
		margin-bottom: var(--spacing-4);
		margin-top: calc(var(--spacing-4) * -1);
	}

	.add-btn {
		grid-area: submit;
	}
	.cancel-btn {
		grid-area: cancel;
	}

	.open-btn {
		margin-left: auto;
	}
`;

interface ApprovalCommentFormProps {
	courseId: string;
	type: string;
}

interface FormValues {
	comment: string;
}

const ApprovalCommentForm = ({ courseId, type }: ApprovalCommentFormProps) => {
	const { currentUser } = useContext(GlobalContext);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormValues>();
	const [isOpen, setIsOpen] = useState(false);
	const openForm = () => setIsOpen(true);
	const closeForm = () => setIsOpen(false);

	const onCommentSubmit: SubmitHandler<FormValues> = async (formData) => {
		const msgPath = `${prefix}/comments/${courseId}`;
		const db = getFirestore();
		const commentValue = formData.comment.trim();

		const commenterName = `${currentUser?.user_first} ${currentUser?.user_last}`;
		const currentComment = {
			text: commentValue,
			userName: commenterName,
			userId: currentUser?.user_id,
			documentCreatedAt: Timestamp.now(),
			editorType: type,
			commentType: 'submission',
			thread: [],
			id: uuidv4(),
			isEdited: false,
		} as UserComment;

		try {
			await addDoc(collection(db, msgPath), currentComment);
			showSuccessToast('Comment added');
			closeForm();
			reset();
		} catch (error) {
			showErrorToast('Comment could not be added.');
		}
	};

	return (
		<StyledCommentFormContainer>
			{!isOpen && (
				<StyledPrimaryButton
					size="small"
					type="button"
					onClick={openForm}
					className="open-btn">
					Add Comment
				</StyledPrimaryButton>
			)}
			{isOpen && (
				<form onSubmit={handleSubmit(onCommentSubmit)}>
					<StyledTextArea
						autoFocus
						{...register('comment', {
							required: 'A comment is required',
							validate: (value) => {
								if (value.trim() === '') {
									return 'A comment is required';
								}
								return true;
							},
						})}
					/>
					<FormErrorMessage
						className="error-message"
						isShowing={!!errors?.comment}
						message={errors.comment?.message}
					/>
					<StyledPrimaryButton size="small" type="submit" className="add-btn">
						Add Comment
					</StyledPrimaryButton>
					<StyledSecondaryButton
						className="cancel-btn"
						size="small"
						type="submit"
						onClick={closeForm}>
						Cancel
					</StyledSecondaryButton>
				</form>
			)}
		</StyledCommentFormContainer>
	);
};

export default ApprovalCommentForm;
