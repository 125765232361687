import { useReducer } from 'react';
import { CourseResourceEnum } from '@/Enums/enum';

import { StyledButtonGroup } from './ButtonGroup.Styles';

interface StringMap {
	[key: string]: string;
}

interface ButtonGroupProps {
	filterEvent: (type: CourseResourceEnum) => void;
	types: StringMap;
}

const ButtonGroup = ({ ...props }: ButtonGroupProps) => {
	const { types, filterEvent } = props;
	const initialType = { show: 'all' };

	const typeReducer = (_: StringMap, action: StringMap) => {
		return {
			show: action.type,
		};
	};

	const [state, dispatch] = useReducer(typeReducer, initialType);

	const handleFilter = (type: string) => {
		filterEvent(type as CourseResourceEnum);
		dispatch({ type });
	};

	return (
		<StyledButtonGroup>
			<div>Filter By</div>
			<div className="button-group">
				{Object.entries(types)
					.sort()
					.map((type: string[], i: number) => (
						<button
							key={`${type}-${i.toString()}`}
							className={state.show === type[1] ? 'active' : ''}
							onClick={() => handleFilter(type[1])}
							type="button">
							{type[0]}
						</button>
					))}
			</div>
		</StyledButtonGroup>
	);
};

export default ButtonGroup;
