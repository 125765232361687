import React from 'react';
import dompurify from 'dompurify';
import { StyledH5, StyledP } from '@/Shared/Typography/typography';
import { hasContent, sanitizeOptions } from '@/utils';

interface ParentMapContentProps {
	content?: string;
	header?: string;
}
const ParentMapContent = ({ content, header }: ParentMapContentProps) => {
	const sanitizer = dompurify.sanitize;

	if (!hasContent(content)) {
		return null;
	}

	return (
		<div className="panel-content">
			{header && <StyledH5 mb="8px">{header}</StyledH5>}
			<StyledP
				dangerouslySetInnerHTML={{
					__html: sanitizer(`${content}`, { ...sanitizeOptions }),
				}}
			/>
		</div>
	);
};

export default ParentMapContent;
