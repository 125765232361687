import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import dompurify from 'dompurify';

import GlobalContext from '@/context/GlobalContext';
import elevateLogo from '@/assets/logo.svg';
import { GlobalBodyStyle } from '@/Shared/StyledElements';
import { StyledH4, StyledH3, StyledP } from '@/Shared/Typography/typography';
import useOutsideClick from '@/hooks/useOutsideClick';
import FadeIn from '@/components/animations/FadeIn';
import Loader from '@/components/Loader';
// import Resources from '@/components/Resources';
import { getFormattedDate, sanitizeOptions } from '@/utils';
import PublicNavbar from '@/components/Navbar/PublicNavbar';
import { useWindowSize } from '@/hooks/useWindowSize';
import CourseMap from '@/components/CourseMap/CourseMap';
import { GetAllDisciplinesQuery } from '@/graphql/graphql';
import {
	StyledPanel,
	StyledCloseIcon,
	StyledBG,
	StyledLegend,
	StyledContent,
	StyledSidebar,
	StyleMobileAlert,
} from './ParentMapStyles';
import DescriptionTab from './sub-components/DescriptionTab';
import OutcomeTab from './sub-components/OutcomeTab';
import ResourcesTab from './sub-components/ResourcesTab';
import UnitFocusTab from './sub-components/UnitFocusTab';
import { getMapCourse, useSiteSettings } from './ParentMapContainers';
import { Tabs, Panel } from './sub-components/ParentMapTabs';

const ParentMap = () => {
	const { divisions } = useContext(GlobalContext);
	const [isOpen, setIsOpen] = useState(false);

	const [activePanel, setActivePanel] = useState(false);
	const [currentCourseId, setCurrentCourseId] = useState('');
	const ref = useRef<HTMLDivElement>(document.createElement('div'));
	const [activeSidebar, setActiveSidebar] = useState(true);
	const { data: settings, isLoading: settingsLoading } = useSiteSettings();

	const {
		isLoading: courseLoading,
		refetch,
		data,
	} = useQuery({
		queryKey: ['get-map-course', currentCourseId],
		queryFn: getMapCourse,

		enabled: true,
		staleTime: Infinity,
	});

	const openPanel = useCallback(
		(id: string) => {
			setCurrentCourseId(id);
			setActivePanel(true);
			refetch();
		},
		[refetch]
	);

	useOutsideClick(ref, () => {
		if (isOpen) {
			setIsOpen(false);
		}
	});

	const closePanel = () => {
		setActivePanel(false);
	};

	const [width] = useWindowSize();

	if (width <= 1200 && !activeSidebar) {
		setActiveSidebar(false);
	}

	useEffect(() => {
		const root = document.documentElement;
		if (settings?.meta?.primary_color) {
			root.style.setProperty('--org-color', settings.meta.primary_color);
		}
	}, [settings]);

	if (settingsLoading) {
		return <Loader />;
	}

	return (
		<FadeIn delay={0}>
			<>
				<GlobalBodyStyle />
				<StyleMobileAlert>
					<div>
						<img src={`${elevateLogo}`} alt="Elevate Logo" />
						<StyledP>
							For the best experience using this page, we recommend not using a mobile
							device or increasing your current window size.
						</StyledP>
					</div>
				</StyleMobileAlert>
				<PublicNavbar isSearchable />
				<StyledBG />
				<StyledContent>
					<StyledSidebar>
						<div
							className="legend-mission"
							style={{ backgroundImage: `url(${settings?.meta.banner})` }}>
							<div className="overlay" />
							{settings?.meta.statement_title && (
								<StyledH4 mb="8px">{settings?.meta.statement_title}</StyledH4>
							)}
							<StyledP
								dangerouslySetInnerHTML={{
									__html: dompurify.sanitize(
										`${settings?.meta?.statement ?? ''}`,
										{
											...sanitizeOptions,
										}
									),
								}}
							/>
						</div>
						<StyledLegend className="map-legend">
							<strong>Course Disciplines</strong>
							<div>
								<div className="discipline-wrap">
									{settings?.disciplines.map(
										(
											discipline: Pick<
												GetAllDisciplinesQuery['dev_disciplines'][0],
												'id' | 'name' | 'color'
											>
										) => (
											<div className="legend-item" key={discipline.id}>
												<div
													className="color-indicator"
													style={{
														backgroundColor: discipline.color ?? '',
													}}
												/>
												<span>{discipline.name}</span>
											</div>
										)
									)}
								</div>
								<strong>Course Divisions</strong>
								<div className="divisions-wrap">
									{divisions?.map((d: any) => (
										<div className="legend-item" key={d.id}>
											<span className="letter-indicator">
												{d.name.split('')[0].toUpperCase()}S
											</span>
											<span>{d.name}</span>
										</div>
									))}
								</div>
							</div>
						</StyledLegend>
					</StyledSidebar>
					<CourseMap isAdmin={false} passedEvent={openPanel} />
					{activePanel && (
						<FadeIn style={{ height: '100%' }}>
							<>
								<StyledPanel>
									<StyledCloseIcon
										top="10px"
										right="-48px"
										className="parent-course-panel-close-btn"
										passedEvent={closePanel}
									/>
									{courseLoading && (
										<Loader
											size="small"
											ringColor="var(--org-color)"
											color="var(--org-color)"
										/>
									)}

									{!courseLoading && (
										<FadeIn className="wrapper parent-map-course-panel">
											<>
												<div className="course-panel-header">
													<StyledH3
														mb="var(--spacing-2)"
														className="course-title">
														{data?.course_name}
													</StyledH3>
													{data?.last_updated && (
														<time
															className="date"
															dateTime={data.last_updated}>
															Last Updated{' '}
															{getFormattedDate(data.last_updated)}
														</time>
													)}
												</div>
												<Tabs selected={0}>
													<Panel title="Description">
														<DescriptionTab
															courseDescription={
																data?.course_description ?? ''
															}
															courseExtras={data?.course_extras ?? ''}
															coursePrereq={data?.course_prereq ?? ''}
														/>
													</Panel>
													<Panel title="Outcomes">
														<OutcomeTab
															courseOutcome={data?.courses_outcomes}
														/>
													</Panel>
													<Panel title="Resources">
														<ResourcesTab
															courseResource={data?.courses_resources}
															courseResourceInfo={
																data?.course_resource_info ?? ''
															}
														/>
													</Panel>
													<Panel title="Units of Focus">
														<UnitFocusTab
															courseUnit={data?.courses_focuses}
														/>
													</Panel>
												</Tabs>
											</>
										</FadeIn>
									)}
								</StyledPanel>
							</>
						</FadeIn>
					)}
				</StyledContent>
			</>
		</FadeIn>
	);
};

export default ParentMap;
