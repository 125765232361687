import { useContext } from 'react';
import { StyledSecondaryButton } from '@/Shared/StyledElements';
import CourseEditContext from '../CourseEditContext';

interface CourseButtonGroupProps {
	courseFocusId: string;
	inputFocusRef: { current: boolean };
}

const CourseButtonGroup = ({ courseFocusId, inputFocusRef }: CourseButtonGroupProps) => {
	const { addSkill, canEdit, isApprovalMode } = useContext(CourseEditContext);

	const setNewFocus = (id: string, type: string) => {
		addSkill(id, type);
		inputFocusRef.current = true;
	};

	return (
		<div className="actions">
			{canEdit && !isApprovalMode && (
				<StyledSecondaryButton
					onClick={() => setNewFocus(courseFocusId, 'topic')}
					size="small"
					type="button">
					Add Subtopic/Description
				</StyledSecondaryButton>
			)}
			{canEdit && !isApprovalMode && (
				<StyledSecondaryButton
					onClick={() => setNewFocus(courseFocusId, 'question')}
					size="small"
					type="button">
					Add Essential Question
				</StyledSecondaryButton>
			)}
			{canEdit && !isApprovalMode && (
				<StyledSecondaryButton
					onClick={() => setNewFocus(courseFocusId, 'skill')}
					size="small"
					type="button">
					Add Skill
				</StyledSecondaryButton>
			)}
		</div>
	);
};

export default CourseButtonGroup;
