interface EditIconProps {
	isAdmin: boolean;
	isAddingCourse: boolean;
	rectNode: any;
	nodeEnter: any;
}

export function createEditIcons(props: EditIconProps) {
	const { isAdmin, isAddingCourse, rectNode, nodeEnter } = props;
	if (isAdmin) {
		const yPos = isAddingCourse ? rectNode.height / 2 - 6.5 : rectNode.height / 2 - 0.5;

		// append plus | minus path icon
		nodeEnter
			.append('path')
			.style('pointer-events', 'none')
			.attr('stroke', 'white')
			.attr('fill', 'white')
			.attr(
				'transform',
				`translate(
			${rectNode.width + 43}, ${yPos})
		`
			)
			.attr('d', (d: any) => {
				if (isAddingCourse) {
					return 'M7.5 6.5H14v1H7.5V14h-1V7.5H0v-1h6.5V0h1z';
				}
				// hide plus icon for root node
				if (d.depth === 0) {
					return null;
				}
				// hide plus icon for discipline node
				if (d.depth === 1 && d.data.isDiscipline && d.children?.length > 0) {
					return null;
				}
				return 'M14 .5v1H0v-1z';
			});

		// append merge path icon
		nodeEnter
			.append('path')
			.style('pointer-events', 'none')
			.attr('stroke', 'white')
			.attr('fill', 'white')
			.attr('transform', () => {
				const xPos = isAddingCourse ? -4.5 : -7.5;

				return `translate(${xPos},-4.5)`;
			})
			.attr('d', (d: any) => {
				const hasMultiChildren = d.ancestors().filter((a: any) => {
					if (a.children?.length >= 2) {
						return a;
					}
					return false;
				});
				const isOrphan = hasMultiChildren[0]?.depth === 0;

				if (isAddingCourse && d.depth !== 0 && !isOrphan) {
					return 'M1 .5L4.2 8 7.3.5h1V9h-.7V1.5L4.5 9h-.6L.7 1.5l.1 3.8V9H.1V.5h1z';
				}
				if (d.data.suppParent && d.data.suppParent?.length !== 0) {
					return 'M3.3 5.4H.8V9H0V.5h2.7c1 0 1.7.2 2.2.6.5.5.7 1 .7 1.9 0 .5-.1 1-.4 1.4-.4.4-.8.7-1.3.9L6 8.9V9h-.8l-2-3.6zM.8 4.8H3c.6 0 1-.1 1.5-.5.3-.3.5-.8.5-1.3 0-.6-.2-1-.6-1.4C4 1.2 3.5 1 2.8 1h-2v3.7zM8.7.5L11.8 8 15 .5h1V9h-.8V1.5L12.2 9h-.6L8.4 1.5V9h-.7V.5h1z';
				}
				return null;
			});
	}
}
