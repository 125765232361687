const QuestionMarkIcon = () => (
	<>
		<svg viewBox="0 0 109 108" fill="none" xmlns="http://www.w3.org/2000/svg" width="16">
			<path
				d="M56.917 84.6a3.557 3.557 0 10-5.03-5.03 3.557 3.557 0 005.03 5.03zM67.41 49.646c3.416-3.598 5.155-8.312 4.897-13.276-.484-9.32-8.177-16.767-17.515-16.955-9.071-.203-16.851 6.441-18.103 15.41-.114.822-.172 1.66-.172 2.494a2.95 2.95 0 105.9 0c0-.562.04-1.127.117-1.679.839-6.008 6.045-10.437 12.139-10.325 6.259.126 11.415 5.117 11.74 11.361a11.918 11.918 0 01-3.283 8.906 11.897 11.897 0 01-8.707 3.743 2.95 2.95 0 00-2.95 2.95v15.64a2.95 2.95 0 105.901 0V54.988c3.82-.626 7.309-2.469 10.036-5.341z"
				fill="currentColor"
			/>
			<path
				d="M108.425 54c0 26.375-19.009 48.392-44.046 53.077 0 0-4.14.692-6.557.828-2.417.137-6.558 0-6.558 0-19.343-1.122-35.984-12.47-44.588-28.707l-3.148-7.157a53.763 53.763 0 01-3.103-18.04 53.966 53.966 0 0113.271-35.458 2.95 2.95 0 114.45 3.876A48.064 48.064 0 006.326 54c0 26.521 21.577 48.098 48.098 48.098 26.522 0 48.099-21.577 48.099-48.098 0-26.521-21.577-48.098-48.099-48.098-9.533 0-18.746 2.782-26.643 8.047a2.95 2.95 0 11-3.273-4.911C33.378 3.125 43.722 0 54.424 0c29.776 0 54.001 24.224 54.001 54z"
				fill="currentColor"
			/>
		</svg>
	</>
);

export default QuestionMarkIcon;
