import ReactModal from 'react-modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import sortBy from 'just-sort-by';
import { GlobalModalStyle, StyledInput, StyledPrimaryButton } from '@/Shared/StyledElements';
import { StyledH3 } from '@/Shared/Typography/typography';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';

import { FormErrorMessage } from '@/components/FormErrorMessage';
import { prefix, renameAndDestructure } from '@/utils';
import { GetAllDivisionsQuery } from '@/graphql/graphql';
import {
	modalStyles,
	StyledFooterBody,
	StyledModalBody,
	StyledModalHeader,
	StyledCloseIcon,
} from './DivisionsListStyles';
import { updateDivisions, createDivision } from './DivisionsContainers';

interface DivisionModalProps {
	triggerModal(): void;
	modalData: {
		isActive: boolean;
		isEditing: boolean;
		division: GetAllDivisionsQuery['dev_divisions'][0] | null;
	};
	allDivisions: GetAllDivisionsQuery['dev_divisions'] | undefined;
}

interface FormValues {
	divisionName: string;
}

const DivisionListModal = ({ modalData, triggerModal, allDivisions }: DivisionModalProps) => {
	const { isEditing, division, isActive } = modalData;
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormValues>();
	const queryClient = useQueryClient();
	const { mutate: divisionUpdateMutation, status: updateStatus } = useMutation({
		mutationFn: updateDivisions,
		onSuccess: (returnValue) => {
			showSuccessToast('Division Updated');
			queryClient.invalidateQueries({
				queryKey: ['get-settings-divisions-disciplines'],
			});
			// update query cache
			const previousDivisionData = queryClient.getQueryData([
				'get-all-divisions',
			]) as GetAllDivisionsQuery['dev_divisions'];
			const globalSettingObj = queryClient.getQueryData([
				'get-settings-divisions-disciplines',
			]) as SettingsDivisionDiscipline;
			const idx = previousDivisionData.findIndex(
				(division) => division.id === returnValue?.id
			);
			// need to clone data in order to not mutate the cache
			const clone = [...previousDivisionData];
			if (returnValue !== undefined) {
				clone[idx] = returnValue;
				queryClient.setQueryData(['get-all-divisions'], clone);
				queryClient.setQueryData(['get-settings-divisions-disciplines'], {
					...globalSettingObj,
					divisions: clone,
				});
			}
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});
	const { mutate: divisionCreateMutation, status: createStatus } = useMutation({
		mutationFn: createDivision,
		onSuccess: (data: { insert_divisions_one: Division }) => {
			const { insert_divisions_one: createdDivision } = renameAndDestructure(data, prefix);
			showSuccessToast('Division Added');

			// update query cache
			const previousDivisionData = queryClient.getQueryData([
				'get-all-divisions',
			]) as Division[];
			const updatedCache = sortBy([createdDivision, ...previousDivisionData], 'name');
			queryClient.setQueryData(['get-all-divisions'], updatedCache);
			queryClient.invalidateQueries({
				queryKey: ['get-settings-divisions-disciplines'],
			});
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const onSubmit: SubmitHandler<FormValues> = (formData) => {
		const { divisionName } = formData;
		if (isEditing) {
			divisionUpdateMutation({
				id: division?.id ?? '',
				name: divisionName.trim(),
				previousState: division,
			});
			return;
		}
		divisionCreateMutation({
			name: divisionName.trim(),
		});
	};

	// TODO: move to higher level utils file
	const isUnique = (value: string) => {
		const isPresent = allDivisions?.some(
			(division) => division?.name?.toLowerCase() === value.toLowerCase()
		);
		return isPresent;
	};

	ReactModal.setAppElement('#root');

	return (
		<ReactModal
			closeTimeoutMS={100}
			isOpen={isActive}
			shouldCloseOnEsc={false}
			onRequestClose={triggerModal}
			contentLabel="Division Modal"
			style={modalStyles}>
			<GlobalModalStyle />
			<StyledModalHeader>
				<StyledCloseIcon passedEvent={triggerModal} />
				{isEditing ? (
					<StyledH3 mb="0" mr="var(--spacing-4)">
						Editing: {division?.name}
					</StyledH3>
				) : (
					<StyledH3 mb="0" mr="var(--spacing-4)">
						Adding New Division{' '}
					</StyledH3>
				)}
			</StyledModalHeader>
			<StyledModalBody>
				<form onSubmit={handleSubmit(onSubmit)}>
					<StyledInput
						type="text"
						defaultValue={division?.name}
						placeholder="Division Name"
						{...register('divisionName', {
							required: 'Division Name is required',
							validate: (value) => {
								if (value.trim() === '') {
									return 'Division Name is required';
								}
								if (isEditing) {
									return true;
								}
								if (isUnique(value)) {
									return 'This division already exists.';
								}
								return true;
							},
						})}
					/>
					<FormErrorMessage
						className="error-message"
						isShowing={!!errors?.divisionName}
						message={errors.divisionName?.message}
					/>
				</form>
			</StyledModalBody>
			<StyledFooterBody>
				<StyledPrimaryButton
					disabled={[createStatus, updateStatus].includes('pending')}
					type="submit"
					onClick={handleSubmit(onSubmit)}>
					{isEditing ? 'Save' : 'Add Division'}
				</StyledPrimaryButton>
			</StyledFooterBody>
		</ReactModal>
	);
};

export default DivisionListModal;
