import { useContext, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import GlobalContext from '@/context/GlobalContext';
import logo from '@/assets/logo.svg';
import useOutsideClick from '@/hooks/useOutsideClick';
import MapFilters from '@/components/MapFilters/MapFilters';
import { useAuth } from '@/hooks/useAuth';
import { StyledNavbar, StyledAvatar, StyledOptions, StyleOptionsDrawer } from './AdminNavbarStyles';

const AdminNavbar = () => {
	const { updateAuthState, disciplines } = useContext(GlobalContext);
	const [isOpen, setIsOpen] = useState(false);
	const { user } = useAuth();
	const ref = useRef<HTMLDivElement>(document.createElement('div'));
	const navigate = useNavigate();
	const { pathname } = useLocation();

	function renderSearch() {
		if (pathname === '/dashboard/course-map') {
			return <MapFilters dropdownOptions={disciplines ?? []} />;
		}
		return null;
	}

	useOutsideClick(ref, () => {
		if (isOpen) {
			setIsOpen(false);
		}
	});

	const signOut = async () => {
		try {
			updateAuthState({});
			navigate('/');
			const auth = getAuth();
			await auth.signOut();
		} catch (error) {
			// TODO: handle error
		}
	};
	const toggleOptionsDrawer = () => setIsOpen(!isOpen);
	const renderUserAvatar = () => {
		return <div>{user?.email?.split('')[0].toUpperCase()}</div>;
	};

	return (
		<StyledNavbar>
			<img src={`${logo}`} alt="Elevate logo" className="logo" />
			{renderSearch()}
			<StyledOptions>
				<StyledAvatar type="button" data-testid="avatar" onClick={toggleOptionsDrawer}>
					{renderUserAvatar()}
				</StyledAvatar>
				{isOpen && (
					<StyleOptionsDrawer ref={ref}>
						<button type="button" onClick={signOut}>
							Sign Out
						</button>
					</StyleOptionsDrawer>
				)}
			</StyledOptions>
		</StyledNavbar>
	);
};

export default AdminNavbar;
