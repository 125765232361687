import { create } from 'zustand';

interface IParentMapStore {
	courseCoordsArray: any;
	d3InstanceRef: React.MutableRefObject<any>;
	zoom: React.MutableRefObject<any>;
	duration: number;
	setCourseCoordsArray: (coordsArray: any) => void;
}

const useParentMapStore = create<IParentMapStore>((set) => ({
	courseCoordsArray: undefined,
	d3InstanceRef: { current: null },
	zoom: { current: {} },
	duration: 750,
	setCourseCoordsArray: (coordsArray) => {
		set({ courseCoordsArray: coordsArray });
	},
}));

export default useParentMapStore;
