import styled from 'styled-components';

const StyledIcon = styled.button`
	align-items: center;
	background-color: rgba(230, 76, 101, 0.5);
	border-radius: 50%;
	border: none;
	cursor: pointer;
	display: inline-flex;
	height: 34px;
	transition: background-color 0.3s;
	width: 34px;

	&:active,
	&:focus,
	&:hover {
		outline: none;
		background-color: rgba(230, 76, 101, 1);
	}
`;

type IconProps = {
	dismissRow(): void;
};

const MinusIcon = ({ dismissRow }: IconProps) => (
	<StyledIcon onClick={dismissRow}>
		<svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0)" fill="#484969">
				<path d="M16.3 1.658a11.552 11.552 0 00-3.952-.691c-3.051 0-5.92 1.169-8.078 3.291C2.113 6.381.925 9.203.925 12.205c0 2.255.676 4.43 1.955 6.291a11.385 11.385 0 005.036 4.071.34.34 0 00.44-.176.328.328 0 00-.18-.432 10.717 10.717 0 01-4.74-3.833 10.405 10.405 0 01-1.84-5.92c0-2.826 1.118-5.483 3.149-7.481a10.771 10.771 0 017.603-3.099c1.28 0 2.531.22 3.72.65a.337.337 0 00.431-.195.328.328 0 00-.199-.423zM22.034 6.245a11.395 11.395 0 00-4.523-4.067.338.338 0 00-.45.145.327.327 0 00.147.443 10.728 10.728 0 014.257 3.83 10.425 10.425 0 011.635 5.61c0 2.825-1.118 5.482-3.15 7.48a10.771 10.771 0 01-7.602 3.098c-.973 0-1.937-.127-2.865-.38a.336.336 0 00-.412.231.33.33 0 00.234.406c.986.267 2.01.403 3.043.403 3.05 0 5.92-1.169 8.077-3.292 2.157-2.122 3.346-4.945 3.346-7.947 0-2.115-.601-4.176-1.738-5.96z" />
				<path d="M16.649 11.876H8.047a.333.333 0 00-.336.33c0 .182.15.33.336.33h8.602a.333.333 0 00.335-.33c0-.183-.15-.33-.335-.33z" />
			</g>
			<defs>
				<clipPath id="clip0">
					<path fill="#fff" transform="translate(.925 .967)" d="M0 0h22.846v22.478H0z" />
				</clipPath>
			</defs>
		</svg>
		<span className="screen-reader-text">Dismiss Icon</span>
	</StyledIcon>
);

export default MinusIcon;
