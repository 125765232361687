import { StyledPrimaryButton, StyledSecondaryButton } from '@/Shared/StyledElements';
import { useCourseLessonsStore } from '@/stores/courseEditStore';
import { useContext } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import CourseEditContext from '../CourseEditContext';
import { StyledLessonPlanFooter } from './CourseLessonPlans.Styles';

const LessonPlanFooter = () => {
	const { addLesson, lessons } = useCourseLessonsStore();
	const { courseData, updatePlans } = useContext(CourseEditContext);
	const isDisabled = lessons.length === 0;
	const isMutatingLessons = useIsMutating({ mutationKey: ['save-course-lesson'] });

	return (
		<StyledLessonPlanFooter>
			<StyledSecondaryButton
				type="button"
				className="reject"
				onClick={addLesson}
				disabled={isDisabled}>
				Add Lesson Plan
			</StyledSecondaryButton>
			<StyledPrimaryButton
				type="button"
				className="-btn"
				disabled={isMutatingLessons !== 0}
				onClick={() => updatePlans(lessons, courseData.course_id)}>
				Save Planning
			</StyledPrimaryButton>
		</StyledLessonPlanFooter>
	);
};

export default LessonPlanFooter;
