import { CourseResourceEnum } from '@/Enums/enum';
import { StyledP } from '@/Shared/Typography/typography';

const CourseResourcePlaceholder = ({ isApprovalMode, state, mediaLength, textbookLength }: any) => {
	const isType = (type: string, length: number) => state.show === type && length === 0;
	const allResources = mediaLength + textbookLength;

	const renderText = () => {
		let text = '';

		if (isType(CourseResourceEnum.All, allResources)) {
			text = 'Add the first resource.';
		}
		if (isType(CourseResourceEnum.Media, mediaLength)) {
			text = 'Add the first media resource.';
		}

		if (isType(CourseResourceEnum.Textbook, textbookLength)) {
			text = 'Add the first textbook resource.';
		}

		return text;
	};

	if (isApprovalMode) {
		return null;
	}

	return <StyledP mb="32px">{renderText()}</StyledP>;
};

export default CourseResourcePlaceholder;
