import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';
import { prefix, renameAndDestructure } from '@/utils';
import { courseDisciplineMutation, courseDivisionMutation } from '@/mutations';
import {
	GetAllDisciplinesAndDivisionsQuery,
	GetCoursesQuery,
	UpdateCourseMutation,
} from '@/graphql/graphql';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export function useGetDisciplineDivisions() {
	return useQuery({
		queryKey: ['get-admin-panel-disciplines-divisions'],

		queryFn: async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const results = await graphQLClient.request<GetAllDisciplinesAndDivisionsQuery>(
				gql`
					query GetAllDisciplinesAndDivisions {
						${prefix}disciplines(order_by: { name: asc }) {
							id
							name
							color
							philosophy
							courses_disciplines {
								courses_disciplines_id
								course_id
								discipline_id
							}
						}
						${prefix}divisions(order_by: { name: asc }) {
							id
							name
							courses_divisions {
								courses_divisions_id
								course_id
								division_id
							}
						}
					}
				`
			);
			const { disciplines, divisions } = renameAndDestructure(results, prefix) as {
				disciplines: GetAllDisciplinesAndDivisionsQuery['dev_disciplines'];
				divisions: GetAllDisciplinesAndDivisionsQuery['dev_divisions'];
			};
			return { disciplines, divisions };
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}

export function useGetAdminPanelCourses() {
	return useQuery({
		queryKey: ['get-admin-panel-courses'],

		queryFn: async () => {
			graphQLClient.setHeader('content-type', `application/json`);
			const result = await graphQLClient.request<GetCoursesQuery>(
				gql`
					query GetCourses {
						${prefix}courses(order_by: { course_name: asc }) {
							course_id
							course_name
							grade
							is_ap
							is_archived
							course_discipline
							course_division
							courses_discipline {
								discipline {
									name
								}
							}
							courses_division {
								division {
									name
								}
							}
						}
					}
				`
			);
			const { courses } = renameAndDestructure(result, prefix) as {
				courses: GetCoursesQuery['dev_courses'];
			};
			return courses;
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}

interface UseCourseFragProps {
	courseId: string;
	courseName: string;
	disciplineId: string;
	divisionId: string;
	userRole: string;
	grade: string;
	isAp: boolean;
	isArchived: boolean;
}

export const useCourseMapFrag = async (variables: UseCourseFragProps) => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	const { userRole, courseId, disciplineId, divisionId, ...res } = variables;
	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	await graphQLClient.request(courseDisciplineMutation, { disciplineId, courseId });
	await graphQLClient.request(courseDivisionMutation, { divisionId, courseId });

	const courseMutation = gql`
		mutation UpdateCourse(
			$courseId: uuid!
			$division: String
			$courseName: String!
			$grade: String
			$isAp: Boolean
			$isArchived: Boolean
		) {
			${prefix}update_courses(
				where: { course_id: { _eq: $courseId } }
				_set: {
					course_division: $division
					course_name: $courseName
					grade: $grade
					is_ap: $isAp
					is_archived: $isArchived
				}
			) {
				returning {
					course_id
					course_name
					course_division
					grade
					is_ap
					is_archived
					courses_discipline {
						discipline {
							name
						}
					}
					courses_division {
						division {
							name
						}
					}
				}
			}
		}
	`;

	const data = await graphQLClient.request<UpdateCourseMutation>(courseMutation, {
		...res,
		courseId,
	});
	const result = renameAndDestructure(data, prefix);
	return result;
};
