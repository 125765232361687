import { useContext, useEffect, useState } from 'react';
import SimpleEditor from '@/components/TipTapEditor/SimpleEditor';
import {
	StyledCheckbox,
	StyledCheckboxLabel,
	StyledHR,
	StyledInput,
} from '@/Shared/StyledElements';
import { StyledH5 } from '@/Shared/Typography/typography';
import { useCourseEditStore, useCourseLessonsStore } from '@/stores/courseEditStore';
import FadeIn from '@/components/animations/FadeIn';
import { isObjectEmpty } from '@/utils';
import debounce from 'just-debounce-it';
import CourseEditContext from '../CourseEditContext';
import { StyledLessonPlans, StyledLessonsPlaceholder } from './CourseLessonPlans.Styles';

const CourseLessonPlans = () => {
	const { canEdit, courseData } = useContext(CourseEditContext);
	const { updateCommentTypes } = useCourseEditStore();
	const { lessons, currentGroupIndex, updateLesson, currentIndex, setGroupIndex } =
		useCourseLessonsStore();
	const [group, setGroup] = useState({} as LessonSection);
	const [hasAdvanced, setHasAdvanced] = useState(false);

	const handleCheckboxChange = (e: any) => {
		const { checked } = e.target;
		updateLesson(checked, group, 'hasAdvanced');
		setHasAdvanced(!hasAdvanced);
	};

	useEffect(() => {
		updateCommentTypes(['none']);
		if (lessons.length > 0) {
			const currentGroup =
				lessons.find((lesson) => lesson.id === currentGroupIndex) ?? lessons[0];
			setGroup(currentGroup);
			setHasAdvanced(currentGroup.lessons[currentIndex]?.hasAdvanced ?? false);
		}
	}, [updateCommentTypes, lessons, currentGroupIndex, currentIndex, setGroupIndex, courseData]);

	useEffect(() => {
		if (lessons.length > 0 && currentGroupIndex === '') {
			setGroupIndex(lessons[0].id);
		}
	}, [lessons, setGroupIndex, currentGroupIndex]);

	if (lessons.length === 0 || isObjectEmpty(group)) {
		return <StyledLessonsPlaceholder>No Lesson Plans Available...</StyledLessonsPlaceholder>;
	}

	const currentLesson = group.lessons[currentIndex];

	// ?? Why is this undefined
	if (!currentLesson) {
		return null;
	}

	const handleUpdate = debounce((value: string, group: LessonSection, key: keyof Lesson) => {
		updateLesson(value, group, key);
	}, 500);

	return (
		<StyledLessonPlans>
			<FadeIn>
				<>
					<div className="editor-wrapper">
						<StyledH5 mb="var(--spacing-4)">Lesson Group Title</StyledH5>
						<StyledInput
							type="text"
							value={group.title}
							placeholder="Add a lesson group title..."
							onInput={(e: any) => {
								updateLesson(e.target.value, group, 'group');
							}}
						/>
					</div>
					<StyledHR />
					<div className="editor-wrapper">
						<StyledH5 mb="var(--spacing-4)">Title</StyledH5>
						<StyledInput
							type="text"
							value={currentLesson.title}
							placeholder="Add a title..."
							onInput={(e: any) => {
								updateLesson(e.target.value, group, 'title');
							}}
						/>
					</div>
					<div className="editor-wrapper">
						<StyledH5 mb="var(--spacing-4)">Introduction</StyledH5>
						<SimpleEditor
							isEditable={canEdit}
							content={currentLesson.introduction}
							placeholder="Add introduction ..."
							id={`${currentLesson.id}-introduction`}
							onChange={(value) => {
								handleUpdate(value, group, 'introduction');
							}}
						/>
					</div>
					<div className="editor-wrapper">
						<StyledH5 mb="16px">Lessons & Activities</StyledH5>
						<SimpleEditor
							isEditable={canEdit}
							content={currentLesson.lessons}
							placeholder="Add lessons ..."
							id={`${currentLesson.id}-lessons`}
							onChange={(value) => {
								handleUpdate(value, group, 'lessons');
							}}
						/>
					</div>
					<div className="editor-wrapper">
						<StyledH5 mb="var(--spacing-4)">Objectives</StyledH5>
						<SimpleEditor
							isEditable={canEdit}
							content={currentLesson.objectives}
							placeholder="Add objectives ..."
							id={`${currentLesson.id}-objectives`}
							onChange={(value) => {
								handleUpdate(value, group, 'objectives');
							}}
						/>
					</div>
					<div className="editor-wrapper">
						<StyledH5 mb="var(--spacing-4)">Resources</StyledH5>
						<SimpleEditor
							isEditable={canEdit}
							content={currentLesson.resources}
							placeholder="Add resources ..."
							id={`${currentLesson.id}-resources`}
							onChange={(value) => {
								handleUpdate(value, group, 'resources');
							}}
						/>
					</div>
					<div className="more-option-wrapper">
						<StyledCheckboxLabel htmlFor="advanced">
							<StyledCheckbox
								type="checkbox"
								name="advanced"
								id="advanced"
								onChange={handleCheckboxChange}
								checked={hasAdvanced}
							/>
							More Options
						</StyledCheckboxLabel>
					</div>
					{hasAdvanced && (
						<>
							<StyledHR />
							<div className="editor-wrapper">
								<StyledH5 mb="var(--spacing-4)">Enrichment</StyledH5>
								<SimpleEditor
									isEditable={canEdit}
									content={currentLesson?.enrichment || ''}
									placeholder="Add enrichment ..."
									id={`${currentLesson.id}-enrichment`}
									onChange={(value) => {
										handleUpdate(value, group, 'enrichment');
									}}
								/>
							</div>
							<div className="editor-wrapper">
								<StyledH5 mb="var(--spacing-4)">Support Team Material</StyledH5>
								<SimpleEditor
									isEditable={canEdit}
									content={currentLesson?.supportMaterial || ''}
									placeholder="Add support team material ..."
									id={`${currentLesson.id}-supportMaterial`}
									onChange={(value) => {
										handleUpdate(value, group, 'supportMaterial');
									}}
								/>
							</div>
							<div className="editor-wrapper">
								<StyledH5 mb="var(--spacing-4)">Bellringer</StyledH5>
								<SimpleEditor
									isEditable={canEdit}
									content={currentLesson?.bellringer || ''}
									placeholder="Add bellringer ..."
									id={`${currentLesson.id}-bellringer`}
									onChange={(value) => {
										handleUpdate(value, group, 'bellringer');
									}}
								/>
							</div>
						</>
					)}
				</>
			</FadeIn>
		</StyledLessonPlans>
	);
};

export default CourseLessonPlans;
