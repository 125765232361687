import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { useUserRole } from '@/hooks/useAuth';
import { prefix, renameAndDestructure } from '@/utils';
import { GetDeptUserQuery, GetModalCoursesQuery, GetUsersQuery } from '@/graphql/graphql';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export function useGetUsers() {
	const { data: userRoleData } = useUserRole();

	return useQuery({
		queryKey: ['get-users'],

		queryFn: async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role ?? '');

			const getGetUsers = gql`
				query GetUsers {
					${prefix}users(order_by: { user_first: asc }) {
						user_email
						user_firebase_id
						user_first
						user_id
						user_last
						user_role
						courses {
							course_id
							course_name
						}
						dept_chair {
							user_last
							user_first
							user_id
						}
						courses_users {
							courses_users_id
							user_id
							course_id
							course {
								course_name
							}
						}
					}
				}
			`;

			const result = await graphQLClient.request<GetUsersQuery>(getGetUsers);
			const { users } = renameAndDestructure(result, prefix) as {
				users: GetUsersQuery['dev_users'];
			};

			return users;
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}

export function useDeptChairs() {
	const { data: userRoleData } = useUserRole();
	return useQuery({
		queryKey: ['get-dept-chairs'],

		queryFn: async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role ?? '');

			const usersQuery = gql`
				query GetDeptUser {
					${prefix}users(
						where: { user_role: { _eq: "dept" } }
						order_by: { user_first: asc }
					) {
						user_first
						user_last
						user_id
						user_firebase_id
					}
				}
			`;

			const result = await graphQLClient.request<GetDeptUserQuery>(usersQuery);
			const { users } = renameAndDestructure(result, prefix) as {
				users: GetDeptUserQuery['dev_users'];
			};
			return users;
		},

		staleTime: Infinity,
	});
}

export function useGetModalCourses() {
	const { data: userRoleData } = useUserRole();
	return useQuery({
		queryKey: ['get-modal-corses'],

		queryFn: async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role ?? '');

			const result = await graphQLClient.request<GetModalCoursesQuery>(
				gql`
					query GetModalCourses {
						${prefix}courses(order_by: { course_name: asc }) {
							course_name
							course_id
						}
					}
				`
			);
			const { courses } = renameAndDestructure(result, prefix) as {
				courses: GetModalCoursesQuery['dev_courses'];
			};
			return courses;
		},
	});
}
