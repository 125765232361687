import { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormErrorMessage } from '@/components/FormErrorMessage';
import {
	StyledCheckbox,
	StyledCheckboxLabel,
	StyledHR,
	GlobalModalStyle,
	StyledPrimaryButton,
	StyledInput,
	StyledTextArea,
} from '@/Shared/StyledElements';
import { StyledH3, StyledH4, StyledH5, StyledP } from '@/Shared/Typography/typography';
import { useUserRole } from '@/hooks/useAuth';
import ColorPicker from '@/components/ColorPicker';
import Tag from '@/components/Tag/Tag';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import { GetAllDisciplinesQuery } from '@/graphql/graphql';
import {
	modalStyles,
	StyledFooterBody,
	StyledForm,
	StyledModalBody,
	StyledModalHeader,
	StyledCheckboxContainer,
	StyledCloseIcon,
} from './TagListStyles';
import { addNewTagFrag, editTagFrag } from './TagListContainers';

interface TagListModalProps {
	triggerModal(): void;
	modalActive: boolean;
	isEditing?: boolean;
	tagData: CourseTag;
	tagList: TagTableItem[];
	disciplines: GetAllDisciplinesQuery['dev_disciplines'] | undefined;
}

interface FormValues {
	tagFormName: string;
	tagFormColor: string;
	tagFormDefinition: string;
}

const TagListModal = ({
	modalActive,
	triggerModal,
	isEditing,
	tagData,
	tagList,
	disciplines,
}: TagListModalProps) => {
	const {
		tag_color: tagColor,
		tag_definition: tagDefinition,
		tag_id: tagId,
		tag_name: tagName,
		tag_discipline: tagDiscipline,
	} = tagData;

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		reset,
		control,
		formState: { isSubmitting },
	} = useForm<FormValues>();
	const [selectedColor, setSelectedColor] = useState(tagColor);
	const [tagDivisions, setTagDivisions] = useState(
		isEditing ? tagDiscipline?.split(',') ?? [] : []
	);
	const { data: userRoleData } = useUserRole();
	const queryClient = useQueryClient();
	const { mutateAsync: newTagMutation } = useMutation({
		mutationFn: addNewTagFrag,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get-all-tags'],
			});
			showSuccessToast('New Tag Added');
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const { mutateAsync: existingTagMutation } = useMutation({
		mutationFn: editTagFrag,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get-all-tags'],
			});
			showSuccessToast('Tag Updated');
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const createTagPreviewName = () => {
		if (watch('tagFormName') === '' || watch('tagFormName')?.trim() === '') {
			return 'Tag Name';
		}
		if (isEditing) {
			return watch('tagFormName', tagName);
		}

		return watch('tagFormName', 'Tag Name');
	};

	const onSubmit: SubmitHandler<FormValues> = async (formData) => {
		const { tagFormName, tagFormColor, tagFormDefinition } = formData;

		const tagData = {
			color: tagFormColor,
			name: tagFormName.trim(),
			definition: tagFormDefinition.trim(),
			role: userRoleData?.user_role,
			discipline: tagDivisions.toString(),
		};

		if (isEditing) {
			await existingTagMutation({
				id: tagId,
				...tagData,
			} as any);
		} else {
			await newTagMutation(tagData as any);
			reset();
		}
	};

	const isUnique = (value: string) => {
		const isPresent = tagList.some(
			(tag: TagTableRow) => tag.nameColumn.toLowerCase() === value.toLowerCase()
		);
		return isPresent;
	};

	const createHeaderValue = () => {
		if (isEditing) {
			return `Editing Tag: ${tagName}`;
		}
		return 'Adding New Tag';
	};

	const createPreviewTag = useCallback(() => {
		let color = selectedColor;

		if (isEditing) {
			color = selectedColor || tagColor;
		}
		return color;
	}, [selectedColor, isEditing, tagColor]);

	const createDefaultValue = useCallback(
		() => (isEditing ? tagName : null),
		[tagName, isEditing]
	);

	function handleDisciplineChange(discipline: GetAllDisciplinesQuery['dev_disciplines'][0]) {
		setTagDivisions((prevState: any) => {
			if (!prevState) {
				return [discipline.name];
			}

			const isExistingCourse = prevState.includes(discipline?.name ?? '');
			if (isExistingCourse) {
				return prevState.filter((d: string) => d !== discipline.name);
			}

			return [...prevState, discipline.name];
		});
	}

	ReactModal.setAppElement('#root');

	return (
		<ReactModal
			closeTimeoutMS={100}
			isOpen={modalActive}
			shouldCloseOnEsc={false}
			onRequestClose={triggerModal}
			onAfterOpen={() => {
				setSelectedColor(tagColor);
			}}
			contentLabel="Tag Modal"
			style={modalStyles}>
			<GlobalModalStyle />
			<StyledModalHeader>
				<StyledCloseIcon passedEvent={triggerModal} />
				<StyledH3 mb="0px">{createHeaderValue()}</StyledH3>
			</StyledModalHeader>
			<StyledModalBody>
				<StyledForm onSubmit={handleSubmit(onSubmit)}>
					<div>
						<label htmlFor="tagFormName">Tag Name</label>
						<StyledInput
							type="text"
							{...register('tagFormName', {
								required: 'Tag Name is required',
								validate: (value) => {
									if (value.trim() === '') {
										return 'Tag Name is required';
									}
									if (isEditing) {
										return true;
									}
									if (isUnique(value.trim())) {
										return 'This name already exists.';
									}
									return true;
								},
							})}
							defaultValue={createDefaultValue()}
						/>
						<FormErrorMessage
							className="error-message"
							isShowing={!!errors?.tagFormName}
							message={errors.tagFormName?.message}
						/>
					</div>
					<div className="tag-preview-container">
						<StyledHR />
						<StyledH4 mb="var(--spacing-4)">Tag Preview</StyledH4>
						<div className="tag-preview">
							<Tag text={createTagPreviewName()} color={selectedColor} />
							<Controller
								control={control}
								name="tagFormColor"
								defaultValue={tagColor}
								render={({ field }) => {
									return (
										<ColorPicker
											onChange={(color) => {
												setSelectedColor(field.value);
												field.onChange(color.hex);
											}}
											color={createPreviewTag()}
										/>
									);
								}}
							/>
						</div>
					</div>
					<StyledHR />
					<div className="tag-definition">
						<label htmlFor="tagFormDefinition">Tag Definition</label>
						<StyledTextArea
							className="textarea"
							defaultValue={isEditing ? tagDefinition : ''}
							{...register('tagFormDefinition', {
								required: 'Tag Definition is required',
								validate: (value) => {
									if (value.trim() === '') {
										return 'Tag Definition is required';
									}
									return true;
								},
							})}
						/>
						<FormErrorMessage
							className="error-message"
							isShowing={!!errors?.tagFormDefinition}
							message={errors.tagFormDefinition?.message}
						/>
					</div>
					<StyledCheckboxContainer>
						<StyledH5 mb="0" mt="16px">
							Tag Disciplines
						</StyledH5>
						<StyledP mb="16px">
							If no discipline is selected, then this tag will be available to all
							disciplines.
						</StyledP>
						{disciplines?.map((d) => {
							const { id, name } = d;
							// check for exact discipline name
							const regex = new RegExp(`\\b(?:${name})\\b`, 'gi');
							// set checked to true if discipline is existing when editing or default to false
							const isChecked = isEditing
								? Boolean(tagDiscipline?.match(regex))
								: false;
							return (
								<StyledCheckboxLabel htmlFor={name ?? ''} key={id}>
									<StyledCheckbox
										type="checkbox"
										id={name ?? ''}
										onChange={() => handleDisciplineChange(d)}
										defaultChecked={isChecked}
									/>
									{name}
								</StyledCheckboxLabel>
							);
						})}
					</StyledCheckboxContainer>
					<StyledHR />
				</StyledForm>
			</StyledModalBody>
			<StyledFooterBody>
				<StyledPrimaryButton
					disabled={isSubmitting}
					type="submit"
					onClick={handleSubmit(onSubmit)}>
					{isSubmitting ? 'Saving Tag...' : 'Save Tag'}
				</StyledPrimaryButton>
			</StyledFooterBody>
		</ReactModal>
	);
};

export default TagListModal;
