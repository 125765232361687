import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';

import { SearchIcon } from '@/icons/index';
import Select from 'react-select';
import { themeSelectStyles } from '@/utils';
import { StyledFilters, StyledSearch, StyledWrapper } from './TableFilters.Styles';

interface TableFiltersProps {
	data: any;
	handleSetData(data: any[]): void;
	filteredColumn: string;
	resultQty: number;
	search?: boolean;
	dropdown?: boolean;
	searchColumn: string[];
}

interface CourseListItem {
	value: string;
	label: string;
	default?: boolean;
}

const TableFilters = ({ ...props }: TableFiltersProps) => {
	const {
		data,
		handleSetData,
		searchColumn,
		filteredColumn,
		resultQty,
		search = true,
		dropdown = true,
	} = { ...props };

	const createKeys = () => {
		const allColumns = searchColumn.map((column) => {
			if (column === 'courseTeacher') {
				return [
					'courseTeacher.courses_users.user.user_first',
					'courseTeacher.courses_users.user.user_last',
				];
			}
			if (column === 'teacherColumn') {
				return [
					'teacherColumn.course.courses_users.user.user_first',
					'teacherColumn.course.courses_users.user.user_last',
				];
			}
			if (column === 'statusColumn') {
				return ['statusColumn.adminApproval', 'statusColumn.deptApproval'];
			}
			return column;
		});
		return allColumns.flat();
	};

	const options = {
		useExtendedSearch: true,
		threshold: 0.0,
		findAllMatches: true,
		shouldSort: true,
		ignoreLocation: true,
		keys: createKeys(),
	};

	const courseArray: CourseListItem[] = Array.from(
		new Set(data.map((x: any) => x[filteredColumn]))
	);
	const courseList: CourseListItem[] = [{ value: '', label: '', default: true }];
	courseArray.forEach((x: any) => {
		courseList.push({ value: x, label: x });
	});

	const [selectVal, setSelectVal] = useState('');
	const [searchInput, setSearchInput] = useState('');
	const fuse = new Fuse(data, options);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		const searchValue = event.target.value;

		const result = fuse.search(event.target.value) as Fuse.FuseResult<CourseTableRow>[];
		if (searchValue === '') {
			handleSetData(data);
		}
		if (selectVal === '' && searchValue !== '') {
			handleSetData(result.map((res) => res.item));
		}
		if (selectVal !== '' && searchValue !== '') {
			const result = fuse.search(searchValue) as Fuse.FuseResult<CourseTableRow>[];

			const filterResults = result.filter((res) => {
				const item = res.item as any;
				return item[filteredColumn] === selectVal;
			});
			handleSetData(filterResults.map((res) => res.item));
		}
		if (selectVal !== '' && searchValue === '') {
			const result = fuse.search(searchInput) as Fuse.FuseResult<CourseTableRow>[];

			handleSetData(result.map((res) => res.item));
		}

		setSearchInput(event.target.value);
	};

	useEffect(() => {
		handleSetData(data);
	}, [data, handleSetData]);

	const clearSearch = () => {
		setSearchInput('');
		if (selectVal === '') {
			handleSetData(data);
		} else {
			const filterResults = data.filter((res: any) => res[filteredColumn] === selectVal);
			handleSetData(filterResults);
		}
	};

	const chooseOption = (initialOption: string) => {
		const option = initialOption || '';

		if (option === '' || !option) {
			handleSetData(data);
		}
		if (searchInput === '' && option !== '') {
			const filteredResult = data.filter((res: any) => res[filteredColumn] === option);
			handleSetData(filteredResult);
		}
		if (searchInput !== '' && option !== '') {
			const result = fuse.search(searchInput) as Fuse.FuseResult<CourseTableRow>[];

			const filteredResult = result.filter((res: any) => res.item[filteredColumn] === option);
			handleSetData(filteredResult.map((res) => res.item));
		}
		if (searchInput !== '' && option === '') {
			const result = fuse.search(searchInput) as Fuse.FuseResult<CourseTableRow>[];

			handleSetData(result.map((res) => res.item));
		}
		setSelectVal(option);
	};

	const renderResultCount = () => {
		if (resultQty === 1) {
			return <div className="result-count">{resultQty} item</div>;
		}
		if (data.length !== resultQty && searchInput !== '') {
			return <div className="result-count">{resultQty} items</div>;
		}
		return <div className="result-count">{resultQty} items</div>;
	};

	const formattedList = (list: any) => {
		const itemList = list.map((item: any) => item[filteredColumn]);

		return [...new Set(itemList)].map((item: any) => {
			return {
				value: item,
				label: item,
				id: item,
			};
		});
	};

	return (
		<StyledWrapper>
			<StyledFilters>
				{renderResultCount()}
				{search && (
					<StyledSearch className={!dropdown ? 'is-single' : ''}>
						<SearchIcon />
						<input
							placeholder="Search..."
							aria-label="Search"
							value={searchInput}
							onChange={(e) => handleChange(e)}
						/>
						{searchInput && (
							<button type="button" data-testid="clear-btn" onClick={clearSearch}>
								<svg
									aria-hidden="true"
									width="8"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor">
									<path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z" />
								</svg>
								<span className="screen-reader-text">Clear Search</span>
							</button>
						)}
					</StyledSearch>
				)}
				{dropdown && (
					<Select
						data-testid="filterSelect"
						styles={themeSelectStyles}
						placeholder="Filter By..."
						className="filter-select"
						onChange={(option) => {
							chooseOption(option?.value);
						}}
						isClearable
						options={formattedList(data)}
						noOptionsMessage={() => 'No Disciplines Found'}
					/>
				)}
			</StyledFilters>
		</StyledWrapper>
	);
};

export default TableFilters;
