import { useContext, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import GlobalContext from '@/context/GlobalContext';
import useOutsideClick from '@/hooks/useOutsideClick';
import MapFilters from '@/components/MapFilters/MapFilters';
import { useAuth } from '@/hooks/useAuth';
import { QuestionMarkIcon } from '@/icons';
import { isLocalStorageAvailable } from '@/utils';
import { useSiteSettings } from '@/Pages/ParentMap/ParentMapContainers';
import Logo from '@/components/Logo';
import ParentMapIntro from '@/Pages/ParentMap/ParentMapIntro';
import ParentAlignmentIntro from '@/Pages/ParentAlignment/ParentAlignmentIntro';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useAlignmentStore } from '@/stores/alignmentStore';
import {
	StyleOptionsDrawer,
	StyledAvatar,
	StyledNavbar,
	StyledOptions,
} from './PublicNavbarStyles';
import { showErrorToast } from '../ToastNotification';

const PublicNavbar = ({ isSearchable = false }) => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { updateAuthState, siteSettings } = useContext(GlobalContext);
	const ref = useRef<HTMLDivElement>(document.createElement('div'));
	const { data: settings, isLoading: settingsLoading } = useSiteSettings();
	const { alignmentCoursesStore } = useAlignmentStore();
	const [isOpen, setIsOpen] = useState(false);
	const [width] = useWindowSize();

	const tutorialState = () => {
		if (isLocalStorageAvailable() && pathname === '/') {
			return JSON.parse(localStorage.getItem('elevateCourseAlignmentTutorial') ?? 'true');
		}
		if (isLocalStorageAvailable() && pathname !== '/') {
			return JSON.parse(localStorage.getItem('elevateCourseMapTutorial') ?? 'true');
		}
		return 'true';
	};

	const [isEnabled, setIsEnabled] = useState(tutorialState());

	const setAdminNavigation = () => {
		if (!user) {
			return null;
		}

		return (
			<Link to="/dashboard/courses" className="navigation-link">
				Dashboard
			</Link>
		);
	};

	function showTutorial() {
		if (isLocalStorageAvailable()) {
			localStorage.setItem('elevateCourseMapTutorial', 'true');
		}
		setIsEnabled(true);
	}

	const toggleOptionsDrawer = () => setIsOpen(!isOpen);

	const renderUserAvatar = () => {
		return <div>{user?.email?.split('')[0].toUpperCase()}</div>;
	};

	useOutsideClick(ref, () => {
		if (isOpen) {
			setIsOpen(false);
		}
	});

	const signOut = async () => {
		try {
			setIsOpen(false);
			updateAuthState({});
			navigate('/');
			const auth = getAuth();
			await auth.signOut();
		} catch (error) {
			showErrorToast('Unable to sign out...please try again.');
		}
	};

	const showCorrectTutorial = () => {
		if (pathname === '/') {
			return (
				// the intro tutorial needs to wait until the alignment courses
				// are loaded because it uses the data
				alignmentCoursesStore.length > 0 && (
					<ParentAlignmentIntro isEnabled={isEnabled} setIsEnabled={setIsEnabled} />
				)
			);
		}
		return width >= 900 && <ParentMapIntro isEnabled={isEnabled} setIsEnabled={setIsEnabled} />;
	};

	return (
		<>
			{showCorrectTutorial()}
			<StyledNavbar>
				<Logo logoUrl={siteSettings?.logo ?? ''} />
				<div className="navigation">
					{setAdminNavigation()}
					{pathname === '/' && (
						<Link to="/course-map" className="course-map-link navigation-link">
							Course Map
						</Link>
					)}
					{pathname !== '/' && (
						<Link to="/" className="navigation-link">
							Course Alignment
						</Link>
					)}
					<button className="help-btn" type="button" onClick={showTutorial}>
						<QuestionMarkIcon />
						<span>Help</span>
					</button>
					{isSearchable && !settingsLoading && (
						<MapFilters dropdownOptions={settings?.disciplines} />
					)}
					<StyledOptions>
						{user === null && (
							<Link to="/login" className="sign-in-link">
								Sign In
							</Link>
						)}
						{user !== null && (
							<StyledAvatar type="button" onClick={toggleOptionsDrawer}>
								{renderUserAvatar()}
							</StyledAvatar>
						)}
						{isOpen && (
							<StyleOptionsDrawer ref={ref}>
								<button type="button" onClick={signOut}>
									Sign Out
								</button>
							</StyleOptionsDrawer>
						)}
					</StyledOptions>
				</div>
			</StyledNavbar>
		</>
	);
};

export default PublicNavbar;
