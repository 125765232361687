import { gql, GraphQLClient } from 'graphql-request';
import { getAuth } from 'firebase/auth';
import { prefix, renameAndDestructure } from '@/utils';
import { updateMutation } from '@/mutations';
import {
	DeleteCourseMutation,
	GetCourseMapQuery,
	InsertCourseMapMutation,
} from '@/graphql/graphql';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export async function useGetCourseMap() {
	graphQLClient.setHeader('content-type', `application/json`);
	const courseQuery = gql`
		query GetCourseMap {
			${prefix}course_map(where: { isDraft: { _eq: false } }) {
				course_map_json
			}
		}
	`;
	const result = await graphQLClient.request<GetCourseMapQuery>(courseQuery);
	const { course_map: map } = renameAndDestructure(result, prefix) as {
		course_map: GetCourseMapQuery['dev_course_map'];
	};

	return map[0]?.course_map_json ?? '{}';
}

// TODO: allow multiple entries for versioning
export const useMapUpdateFrag = async (variables: { map: string }) => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	const { map } = variables;

	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', 'admin');

	const createMutation = gql`
		mutation InsertCourseMap($map: String!) {
			${prefix}insert_course_map(objects: { isDraft: false, course_map_json: $map }) {
				affected_rows
			}
		}
	`;

	const mutationRes = await graphQLClient.request<InsertCourseMapMutation>(updateMutation, {
		map,
	});
	const results = renameAndDestructure(mutationRes, prefix) as {
		update_course_map: InsertCourseMapMutation['dev_insert_course_map'];
	};

	// Runs on the first time the map is updated
	if (results?.update_course_map?.affected_rows === 0) {
		return graphQLClient.request(createMutation, { map });
	}

	return results;
};

export const useDeleteCourseFrag = async (variables: { id: string; userRole: string }) => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	const { userRole, id } = variables;
	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', userRole);
	const mutation = gql`
		mutation DeleteCourse($id: uuid!) {
			${prefix}delete_courses(where: { course_id: { _eq: $id } }) {
				affected_rows
			}
		}
	`;
	const data = await graphQLClient.request<DeleteCourseMutation>(mutation, { id });
	const response = renameAndDestructure(data, prefix);
	return response;
};
