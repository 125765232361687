import { deleteDoc, doc, getFirestore, Timestamp, updateDoc } from 'firebase/firestore';
import { format, isToday, isYesterday } from 'date-fns';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import { FormEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Editor } from '@tiptap/core';
import { prefix } from '@/utils';

export const sortComments = (comments: UserComment[]) => {
	return [...comments]
		.sort((a, b) => {
			if (a.documentCreatedAt.seconds < b.documentCreatedAt.seconds) {
				return 1;
			}
			if (a.documentCreatedAt.seconds > b.documentCreatedAt.seconds) {
				return -1;
			}
			return 0;
		})
		.reverse();
};

export const renderDate = (dateTime: Date) => {
	const time = 'h:mm a';
	const dateTimeFormat = 'LLL d, yyyy h:mm a';
	const formatTime = format(dateTime, time);
	const formatDate = format(dateTime, dateTimeFormat);

	if (isToday(dateTime)) {
		return `Today ${formatTime}`;
	}
	if (isYesterday(dateTime)) {
		return `Yesterday ${formatTime}`;
	}
	return formatDate;
};

export const replyToComment = async (
	e: FormEvent<HTMLButtonElement>,
	comment: UserComment,
	userName: string,
	courseId: string,
	userId: string
) => {
	const { form } = e.target as HTMLFormElement;
	const message = form.elements.comment.value.trim();
	const msgPath = `${prefix}/comments`;
	const db = getFirestore();
	const thread = [...(comment?.thread ?? [])];

	try {
		await updateDoc(doc(db, `${msgPath}/${courseId}/${comment.uid}`), {
			thread: [
				...thread,
				{
					text: message,
					id: uuidv4(),
					documentCreatedAt: Timestamp.now(),
					commentType: 'general',
					userName,
					userId,
				},
			],
		});
		form.reset();
	} catch (error) {
		showErrorToast('Could not add comment. Please try again.');
	}
};

export const updateComment = async (
	e: FormEvent<HTMLButtonElement>,
	reply: any,
	comment: UserComment,
	courseId: string
) => {
	const { form } = e.target as HTMLFormElement;
	const message = form.elements.comment.value.trim();
	const msgPath = `${prefix}/comments`;
	const db = getFirestore();

	// Updates the parent comment within the thread
	if (reply.uid === comment.uid) {
		try {
			await updateDoc(doc(db, `${msgPath}/${courseId}/${comment.uid}`), {
				text: message,
				isEdited: true,
			});
		} catch (error) {
			showErrorToast('Could not update comment. Please try again.');
		}
		return;
	}

	// Updates the reply within the thread
	const thread = [...(comment?.thread ?? [])];

	if (thread) {
		const index = thread.findIndex((item) => item.id === reply.id);
		thread[index].text = message;
		thread[index].isEdited = true;
	}

	try {
		await updateDoc(doc(db, `${msgPath}/${courseId}/${comment.uid}`), {
			thread: [...thread],
		});
		form.reset();
	} catch (error) {
		showErrorToast('Could not update comment. Please try again.');
	}
};

const removeCommentInEditor = (html: string, commentId: string) => {
	const pattern = `<\\s*span[^>]+data-uid="${commentId}"*>(.*?)<\\s*/\\s*span>`;
	const regex = new RegExp(pattern);
	const matchingTag = html.match(regex);

	if (matchingTag) {
		// 0 is the string used to match the regex
		// 1 is the string within the span tag
		const htmlWithoutComment = html.replace(matchingTag[0], matchingTag[1]);
		return htmlWithoutComment;
	}
	return null;
};

export const removeComment = async (
	comment: UserComment,
	reply: UserComment,
	courseId: string,
	editorList: Record<string, Editor>
) => {
	const db = getFirestore();
	const msgPath = `${prefix}/comments`;
	const editor = editorList[comment.editorType];
	if (editor) {
		const html = editor.getHTML();
		const updatedHTML = removeCommentInEditor(html, comment.id);

		// Updates the editor only if it is the parent comment
		if (updatedHTML && reply.uid === comment.uid) {
			editor.commands.setContent(updatedHTML);
		}
	}

	// Updates the parent comment within the thread
	if (reply.uid === comment.uid) {
		try {
			deleteDoc(doc(db, `${msgPath}/${courseId}/${comment.uid}`));
			showSuccessToast('Comment Deleted');
		} catch (error) {
			showErrorToast('Comment could not be deleted');
		}
		return;
	}

	// Updates the reply within the thread
	const threadClone = [...(comment?.thread ?? [])];
	const newThread = threadClone.filter((item) => item.id !== reply.id);

	try {
		updateDoc(doc(db, `${msgPath}/${courseId}/${comment.uid}`), {
			thread: [...newThread],
		});
	} catch (error) {
		showErrorToast('Could not update comment. Please try again.');
	}
};
