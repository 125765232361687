/* eslint-disable no-await-in-loop */
import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { collection, deleteDoc, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import pMap from 'p-map';

import { SetStatus } from '@/Enums/enum';
import { prefix, renameAndDestructure } from '@/utils';
import {
	Courses,
	GetAllTagsQuery,
	GetCoursesQuery,
	GetUsersQuery,
	Submissions,
	Submissions_Mutation_Response,
	UpdateCourseMutation,
} from '@/graphql/graphql';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
const removeCommentTags = (text: string): string => {
	const regex =
		/<i(?=\s)(?!(?:[^>"\\']|"[^"]*"|\\'[^\\']*\\')*?(?<=\s)(?:term|range)\s*=)(?!\s*>)\s+(?:".*?"|\\'.*?\\'|[^>]*?)+>|<\/i>/gm;

	return text.replace(regex, '');
};

export const useGetAllAdmin = () => {
	const query = gql`
		query GetAllAdmin {
			${prefix}users(where: { user_role: { _eq: "admin" } }) {
				user_email
				user_first
				user_last
			}
		}
	`;

	return useQuery({
		queryKey: ['get-all-admin'],

		queryFn: async () => {
			graphQLClient.setHeader('content-type', `application/json`);
			const result = await graphQLClient.request<GetUsersQuery>(query);
			const { users } = renameAndDestructure(result, prefix) as {
				users: Array<
					Pick<
						GetUsersQuery['dev_users'][number],
						'user_email' | 'user_first' | 'user_last'
					>
				>;
			};

			return users;
		},

		staleTime: Infinity,
	});
};

export function useGetAllTags() {
	const query = gql`
		query GetAllTags {
			${prefix}tags(order_by: { tag_name: asc }) {
				tag_id
				tag_color
				tag_name
				tag_definition
				tag_discipline
			}
		}
	`;

	return useQuery({
		queryKey: ['get-all-tags-admin'],

		queryFn: async () => {
			graphQLClient.setHeader('content-type', `application/json`);
			const result = await graphQLClient.request<GetAllTagsQuery>(query);
			const { tags } = renameAndDestructure(result, prefix) as {
				tags: GetAllTagsQuery['dev_tags'];
			};
			return tags;
		},

		staleTime: Infinity,
	});
}

const CONCURRENT_PROMISES = 3;

interface updateDraftFragProps {
	userRole: string;
	variables: {
		courseDraft: string;
		courseId: string;
		isDraft?: boolean;
	};
}

export const updateDraftFrag = async (config: updateDraftFragProps) => {
	const { variables, userRole } = config;
	const { courseId, courseDraft, isDraft } = variables;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	const updateCourseMutation = (isDraft: boolean | undefined) => gql`
		mutation updateCourse($courseId: uuid!, $courseDraft: String, $isDraft: Boolean) {
			${prefix}update_courses_by_pk(
				pk_columns: { course_id: $courseId }
				_set: {
					working_copy: $courseDraft,
					is_saved_draft: $isDraft,
					${isDraft ? '' : 'course_draft: $courseDraft'}
				}
			) {
				course_id
				submission_id
			}
		}
	`;

	const mutation = updateCourseMutation(isDraft);

	const data = await graphQLClient.request<UpdateCourseMutation>(mutation, {
		courseId,
		courseDraft: isDraft ? removeCommentTags(courseDraft) : courseDraft,
		isDraft: !!isDraft,
	});

	const result = renameAndDestructure(data, prefix);
	return result;
};

export const existingSub = async (config: any) => {
	const { variables, userRole } = config;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken(true);

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	const subQuery = gql`
		query GetCourseSub($courseId: uuid) {
			${prefix}submissions(where: { course_id: { _eq: $courseId } }) {
				submission_id
			}
		}
	`;

	const data = await graphQLClient.request<Submissions[]>(subQuery, variables);
	const { submissions } = renameAndDestructure(data, prefix) as {
		submissions: Array<Pick<Submissions[][number], 'submission_id'>>;
	};
	return submissions[0]?.submission_id;
};

export const updateSubStatusFrag = async (config: any) => {
	const { variables, userRole } = config;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken(true);

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	const subQuery = gql`
		query GetCourseSub($courseId: uuid) {
			${prefix}submissions(where: { course_id: { _eq: $courseId } }) {
				submission_id
			}
		}
	`;

	const data = await graphQLClient.request<Submissions[]>(subQuery, variables);
	const { submissions } = renameAndDestructure(data, prefix) as {
		submissions: Array<Pick<Submissions[][number], 'submission_id'>>;
	};
	const subId = submissions[0]?.submission_id;
	const subMutation = gql`
		mutation UpdateSubmission($subId: uuid) {
			${prefix}update_submissions(
				where: { submission_id: { _eq: $subId } }
				_set: { admin_approval: "waiting", dept_approval: "waiting" }
			) {
				affected_rows
			}
		}
	`;

	const result = await graphQLClient.request<Submissions_Mutation_Response>(subMutation, {
		subId,
	});
	const response = renameAndDestructure(result, prefix);
	return response;
};

// eslint-disable-next-line consistent-return
export const setDraftFrag = async (config: any) => {
	const { variables, userRole } = config;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	const { courseId, status, approveAll } = variables;
	const { draftCourse, publishedCourse } = config;
	const approvalType = userRole === 'admin' ? 'admin_approval' : 'dept_approval';

	const subIdQuery = gql`
		query getSubId($courseId: uuid!) {
			${prefix}submissions(where: { course_id: { _eq: $courseId } }) {
				submission_id
			}
		}
	`;

	await graphQLClient.request(subIdQuery, {
		courseId,
	});

	const courseMutation = gql`
	mutation updateCourse($courseId: uuid!, $status: String) {
			${prefix}update_submissions(
					where: { course_id: { _eq: $courseId } }
					_set: { ${
						approveAll
							? 'admin_approval: $status, dept_approval: $status'
							: `${approvalType}: $status`
					} }
			) {
					returning {
							admin_approval
							dept_approval
					}
			}
	}`;

	const result = await graphQLClient.request(courseMutation, {
		courseId,
		status,
	});

	const { update_submissions: approvalStatus } = renameAndDestructure(result, prefix);

	const submission = approvalStatus.returning[0];
	const isFullyApproved =
		submission.admin_approval === SetStatus.APPROVED &&
		submission.dept_approval === SetStatus.APPROVED;

	const deleteOutcomesMutation = gql`
		mutation deleteOutcomesMutation($outcomeId: uuid!) {
			${prefix}delete_outcomes(where: { outcome_id: { _eq: $outcomeId } }) {
				affected_rows
			}
		}
	`;

	const createOutcomeMutation = gql`
		mutation OutcomeMutation($outcomeText: String, $outcomeOrder: Int) {
			${prefix}insert_outcomes_one(
				object: { outcome_text: $outcomeText, outcome_order: $outcomeOrder }
			) {
				outcome_id
			}
		}
	`;
	const createCourseOutcomeMutation = gql`
		mutation CourseOutcomeMutation($courseId: uuid, $outcomeId: uuid) {
			${prefix}insert_courses_outcomes(objects: { course_id: $courseId, outcome_id: $outcomeId }) {
				affected_rows
			}
		}
	`;

	const deletePublishedOutcomes = async () => {
		const outcomes = publishedCourse.courses_outcomes || [];
		await pMap(
			outcomes,
			async ({ outcome }: any) => {
				if (outcome.outcome_id) {
					await graphQLClient.request(deleteOutcomesMutation, {
						outcomeId: outcome.outcome_id,
					});
				}
			},
			{ concurrency: CONCURRENT_PROMISES }
		);
	};

	if (publishedCourse.courses_outcomes?.length > 0 && isFullyApproved) {
		await deletePublishedOutcomes();
	}

	const publishDraftOutcomes = async () => {
		const outcomes = draftCourse.courses_outcomes || [];
		await pMap(
			outcomes,
			async ({ outcome }: any) => {
				const { outcome_text: outcomeText, outcome_order: outcomeOrder = 0 } = outcome;
				const data = await graphQLClient.request(createOutcomeMutation, {
					outcomeText,
					outcomeOrder,
				});
				const { insert_outcomes_one: insertedOutcome } = renameAndDestructure(data, prefix);
				await graphQLClient.request(createCourseOutcomeMutation, {
					outcomeId: insertedOutcome.outcome_id,
					courseId,
				});
			},
			{ concurrency: CONCURRENT_PROMISES }
		);
	};

	if (draftCourse.courses_outcomes?.length > 0 && isFullyApproved) {
		await publishDraftOutcomes();
	}

	const createResourceMutation = gql`
		mutation ResourceMutation(
			$resourceAuthor: String
			$resourceDetail: String
			$resourceIsbn: String
			$resourceType: String
			$resourceTitle: String
		) {
			${prefix}insert_resources_one(
				object: {
					resource_author: $resourceAuthor
					resource_detail: $resourceDetail
					resource_isbn: $resourceIsbn
					resource_type: $resourceType
					resource_title: $resourceTitle
				}
			) {
				resource_id
			}
		}
	`;
	const createCourseResourceMutation = gql`
		mutation CourseResourceMutation($courseId: uuid, $resourceId: uuid) {
			${prefix}insert_courses_resources(objects: { course_id: $courseId, resource_id: $resourceId }) {
				affected_rows
			}
		}
	`;

	const deleteResourceMutation = gql`
		mutation DeleteResourceMutation($resourceId: uuid!) {
			${prefix}delete_resources(where: { resource_id: { _eq: $resourceId } }) {
				affected_rows
			}
		}
	`;

	const deletePublishedResources = async () => {
		const resources = publishedCourse.courses_resources || [];
		await pMap(
			resources,
			async ({ resource }: any) => {
				if (resource.resource_id) {
					await graphQLClient.request(deleteResourceMutation, {
						resourceId: resource.resource_id,
					});
				}
			},
			{ concurrency: CONCURRENT_PROMISES }
		);
	};

	if (publishedCourse.courses_resources?.length > 0 && isFullyApproved) {
		await deletePublishedResources();
	}

	const publishDraftResources = async () => {
		const resources = draftCourse.courses_resources || [];
		await pMap(
			resources,
			async ({ resource }: any) => {
				const {
					resource_author: resourceAuthor,
					resource_detail: resourceDetail,
					resource_isbn: resourceIsbn,
					resource_type: resourceType,
					resource_title: resourceTitle,
				} = resource;
				const resourceData = await graphQLClient.request(createResourceMutation, {
					resourceAuthor,
					resourceDetail,
					resourceIsbn,
					resourceType,
					resourceTitle,
				});
				const { insert_resources_one: insertedResource } = renameAndDestructure(
					resourceData,
					prefix
				);
				await graphQLClient.request(createCourseResourceMutation, {
					resourceId: insertedResource.resource_id,
					courseId,
				});
			},
			{ concurrency: CONCURRENT_PROMISES }
		);
	};

	if (draftCourse.courses_resources?.length > 0 && isFullyApproved) {
		await publishDraftResources();
	}

	const createFocusMutation = gql`
		mutation FocusMutation($focusTitle: String, $focusOrder: Int) {
			${prefix}insert_focuses_one(object: { focus_title: $focusTitle, focus_order: $focusOrder }) {
				focus_id
			}
		}
	`;
	const createSkillMutation = gql`
		mutation SkillMutation($skillText: String, $skillType: String) {
			${prefix}insert_skills_one(object: { skill_text: $skillText, skill_type: $skillType }) {
				skill_id
			}
		}
	`;

	const createFocusSkillMutation = gql`
		mutation FocusSkillMutation($skillId: uuid, $focusId: uuid) {
			${prefix}insert_focuses_skills_one(object: { skill_id: $skillId, focus_id: $focusId }) {
				focuses_skills_id
			}
		}
	`;

	const deleteFocusMutation = gql`
		mutation DeleteFocusMutation($focusId: uuid!) {
			${prefix}delete_focuses(where: { focus_id: { _eq: $focusId } }) {
				affected_rows
			}
		}
	`;

	const deleteSkillMutation = gql`
		mutation DeleteSkillMutation($skillId: uuid!) {
			${prefix}delete_skills(where: { skill_id: { _eq: $skillId } }) {
				affected_rows
			}
		}
	`;

	const deleteFocusSkillMutation = gql`
		mutation DeleteSkillMutation($focusSkillId: uuid!) {
			${prefix}delete_focuses_skills(where: { focuses_skills_id: { _eq: $focusSkillId } }) {
				affected_rows
			}
		}
	`;

	const createCourseFocusesMutation = gql`
		mutation CourseFocusesMutation($courseId: uuid, $focusId: uuid) {
			${prefix}insert_courses_focuses_one(object: { course_id: $courseId, focus_id: $focusId }) {
				focus_id
			}
		}
	`;

	const deletePublishedFocuses = async () => {
		const focuses = publishedCourse.courses_focuses || [];
		await pMap(
			focuses,
			async ({ focus }: any) => {
				const focusSkills = focus.focuses_skills || [];
				await pMap(
					focusSkills,
					async ({ skill, focuses_skills_id: focusSkillId }: any) => {
						if (focusSkillId) {
							await graphQLClient.request(deleteFocusSkillMutation, { focusSkillId });
						}
						if (skill?.skill_id) {
							await graphQLClient.request(deleteSkillMutation, {
								skillId: skill.skill_id,
							});
						}
					},
					{ concurrency: CONCURRENT_PROMISES }
				);

				if (focus.focus_id) {
					await graphQLClient.request(deleteFocusMutation, { focusId: focus.focus_id });
				}
			},
			{ concurrency: CONCURRENT_PROMISES }
		);
	};

	if (publishedCourse.courses_focuses?.length > 0 && isFullyApproved) {
		await deletePublishedFocuses();
	}

	const publishDraftFocuses = async () => {
		const focuses = draftCourse.courses_focuses || [];
		await pMap(
			focuses,
			async ({ focus }: any) => {
				const { focus_order: focusOrder, focus_title: focusTitle } = focus;
				const focusData = await graphQLClient.request(createFocusMutation, {
					focusTitle,
					focusOrder,
				});
				const { insert_focuses_one: insertedFocus } = renameAndDestructure(
					focusData,
					prefix
				);

				await graphQLClient.request(createCourseFocusesMutation, {
					focusId: insertedFocus.focus_id,
					courseId,
				});

				const focusSkills = focus.focuses_skills || [];
				await pMap(
					focusSkills,
					async ({ skill }: any) => {
						if (skill) {
							const { skill_text: skillText, skill_type: skillType } = skill;
							const skillDefaultType =
								skill.skill_type === '' || skill.skill_type === null
									? 'topic'
									: skillType;
							const skillData = await graphQLClient.request(createSkillMutation, {
								skillText,
								skillType: skillDefaultType,
							});
							const { insert_skills_one: insertedSkill } = renameAndDestructure(
								skillData,
								prefix
							);
							await graphQLClient.request(createFocusSkillMutation, {
								skillId: insertedSkill.skill_id,
								focusId: insertedFocus.focus_id,
							});
						}
					},
					{ concurrency: CONCURRENT_PROMISES }
				);
			},
			{ concurrency: CONCURRENT_PROMISES }
		);
	};

	if (draftCourse.courses_focuses?.length > 0 && isFullyApproved) {
		await publishDraftFocuses();
	}

	if (isFullyApproved) {
		// delete all submission comments if course is fully approved
		const db = getFirestore();
		const msgPath = `${prefix}/comments/${courseId}`;
		const q = query(collection(db, `${msgPath}`), where('commentType', '==', 'submission'));
		onSnapshot(q, (snap) => {
			if (!snap.empty) {
				snap.forEach((doc) => {
					deleteDoc(doc.ref);
				});
			}
		});

		const courseBasicMutation = gql`
			mutation updateCourse(
				$courseId: uuid!
				$courseDescription: String
				$courseExtras: String
				$coursePrereq: String
				$courseResourceInfo: String
			) {
				${prefix}update_courses_by_pk(
					pk_columns: { course_id: $courseId }
					_set: {
						course_description: $courseDescription
						course_resource_info: $courseResourceInfo
						course_extras: $courseExtras
						course_prereq: $coursePrereq
					}
				) {
					course_id
				}
			}
		`;

		const {
			course_description: courseDescription,
			course_resource_info: courseResourceInfo,
			course_extras: courseExtras,
			course_prereq: coursePrereq,
		} = draftCourse;
		await graphQLClient.request(courseBasicMutation, {
			courseId,
			courseDescription,
			courseResourceInfo,
			courseExtras,
			coursePrereq,
		});

		const queryCurrentCourse = gql`
			query currentCourse($courseId: uuid!) {
				${prefix}courses_by_pk(course_id: $courseId) {
					course_name
					course_id
					course_description
					course_resource_info
					course_prereq
					course_extras
					course_discipline
					course_division
					course_draft
					courses_outcomes {
						courses_outcomes_id
						outcome {
							outcome_order
							outcome_text
							outcome_id
						}
					}
					courses_resources {
						course_resources_id
						resource {
							resource_author
							resource_detail
							resource_isbn
							resource_id
							resource_title
							resource_type
						}
					}
					courses_focuses {
						courses_focuses_id
						focus {
							focus_title
							focus_type
							focus_order
							focus_id
							focuses_skills {
								focuses_skills_id
								skill {
									skill_id
									skill_text
									skill_type
								}
							}
						}
					}
				}
			}
		`;

		const courseData = await graphQLClient.request(queryCurrentCourse, {
			courseId,
		});
		const { courses_by_pk: course } = renameAndDestructure(courseData, prefix);

		const newDraft = JSON.stringify(course);

		const courseNewDraftMutation = gql`
			mutation newDraftMutation($courseDraft: String!, $courseId: uuid!, $lastUpdated: date) {
				${prefix}update_courses_by_pk(
					pk_columns: { course_id: $courseId }
					_set: { course_draft: $courseDraft, last_updated: $lastUpdated }
				) {
					course_id
				}
			}
		`;

		const finalData = graphQLClient.request(courseNewDraftMutation, {
			lastUpdated: new Date(),
			courseDraft: newDraft,
			courseId,
		});
		const finalResult = renameAndDestructure(finalData, prefix);
		return finalResult;
	}
};

export const useGetCurrentCourses = (userRoleData: any, courseId?: string) => {
	const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
	const query = gql`
		query GetCurrentCourses($courseId: uuid!) {
			${prefix}courses(where: { course_id: { _eq: $courseId } }) {
				course_name
				course_id
				course_description
				course_resource_info
				course_prereq
				course_extras
				course_discipline
				course_division
				submission_id
				course_draft
				is_archived
				working_copy
				course_lesson_plans
				is_saved_draft
				last_updated
				submission {
					admin_approval
					dept_approval
				}
				courses_discipline {
					discipline {
						name
					}
				}
				courses_users {
					user {
						user_first
						user_last
						user_id
						user_email
						dept_chair {
							user_email
							user_id
						}
					}
				}
				courses_outcomes {
					courses_outcomes_id
					outcome {
						outcome_order
						outcome_text
						outcome_id
					}
				}
				courses_resources {
					course_resources_id
					resource {
						resource_author
						resource_detail
						resource_isbn
						resource_id
						resource_title
						resource_type
					}
				}
				courses_focuses {
					courses_focuses_id
					focus {
						focus_title
						focus_type
						focus_order
						focus_id
						focuses_skills {
							focuses_skills_id
							skill {
								skill_id
								skill_text
								skill_type
							}
						}
					}
				}
			}
		}
	`;

	return useQuery({
		queryKey: ['get-current-course'],

		queryFn: async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();
			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('x-hasura-role', userRoleData.user_role);
			graphQLClient.setHeader('content-type', 'application/json');
			const data = await graphQLClient.request<GetCoursesQuery>(query, {
				courseId,
			});
			const { courses } = renameAndDestructure(data, prefix) as {
				// courses: AllCoursesQuery['dev_courses'];
				courses: Courses[];
			};

			return courses[0];
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
};

export const updateLessonPlans = async ({
	plans,
	courseId,
}: {
	plans: LessonSection[];
	courseId: string;
}) => {
	const jsonPlans = JSON.stringify(plans);
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken(true);

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', 'admin');

	const courseMutation = gql`
		mutation updateCourse($courseId: uuid!, $plans: String!) {
			${prefix}update_courses_by_pk(
				pk_columns: { course_id: $courseId }
				_set: { course_lesson_plans: $plans }
			) {
				course_id
			}
		}
	`;
	const data = await graphQLClient.request<UpdateCourseMutation>(courseMutation, {
		courseId,
		plans: jsonPlans,
	});

	const result = renameAndDestructure(data, prefix);
	return result;
};

export const saveWorkingDraft = async (draft: any, courseId: string) => {
	const jsonDraft = JSON.stringify(draft);

	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken(true);

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', 'admin');

	const courseMutation = gql`
		mutation updateCourse($courseId: uuid!, $draft: String!) {
			${prefix}update_courses_by_pk(
				pk_columns: { course_id: $courseId }
				_set: { course_draft: $draft }
			) {
				course_id
			}
		}
	`;
	const data = await graphQLClient.request<UpdateCourseMutation>(courseMutation, {
		courseId,
		draft: jsonDraft,
	});

	const result = renameAndDestructure(data, prefix);
	return result;
};
