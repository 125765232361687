import { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DragIcon } from '@/icons/index';
import { StyledDragItem } from './CourseOutcomeTab.Styles';
import OutcomeEditor from './OutcomeEditor';
import CourseEditContext from '../CourseEditContext';

interface DraggableOutcomeProps {
	draggableId: string;
	isDragDisabled: boolean;
	index: number;
	outcome: CoursesOutcomesEntity;
}

const DraggableOutcome = ({
	draggableId,
	index,
	outcome,
	isDragDisabled,
}: DraggableOutcomeProps) => {
	const { canEdit, removeOutcome } = useContext(CourseEditContext);
	const removeSetOutcome = (key: string) => removeOutcome(key);
	const { outcome_id: outcomeId, outcome_text: outcomeText } = outcome.outcome;

	return (
		<Draggable
			draggableId={`outcome-${draggableId}`}
			index={index}
			isDragDisabled={isDragDisabled}>
			{(provided, snapshot) => (
				<StyledDragItem
					{...provided.draggableProps}
					ref={provided.innerRef}
					className={snapshot.isDragging ? 'dragging' : ''}>
					{canEdit && (
						<div className="drag-icon-wrapper" {...provided.dragHandleProps}>
							<DragIcon />
						</div>
					)}
					<OutcomeEditor
						text={outcomeText}
						id={outcomeId}
						onRemoveEvent={() => removeSetOutcome(outcome.courses_outcomes_id)}
					/>
				</StyledDragItem>
			)}
		</Draggable>
	);
};
export default DraggableOutcome;
