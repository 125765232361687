import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { ToastNotification } from '@/components/ToastNotification';
import { StyledPrimaryButton } from '@/Shared/StyledElements';
import { StyledP } from '@/Shared/Typography/typography';
import logo from '@/assets/logo.svg';
import { BoxedIconMessage } from '@/components/BoxedIconMessage';
import PasswordInput from '@/components/PasswordInput/PasswordInput';
import {
	StyledBG,
	StyledFeatherBackgroundTop,
	StyledFeatherBackgroundBottom,
	StyledForm,
} from './SetPasswordStyles';

interface FormValues {
	password: string;
	passwordConfirmation: string;
}

const SetPassword = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isSubmitting },
	} = useForm<FormValues>();
	const [messageState, setMessageState] = useState({
		message: '',
		messageColor: 'var(--green-300)',
		hasError: false,
	});
	const navigate = useNavigate();

	const onSubmit: SubmitHandler<FormValues> = async (passwordFormData) => {
		const { password } = passwordFormData;

		const url = new URL(window.location.href);
		const searchParams = new URLSearchParams(url.search);
		const oobCode = searchParams.get('oobCode');
		const apiKey = searchParams.get('apiKey');
		let email = searchParams.get('email');

		// Manually replace ' ' with '+' in the email parameter
		// useful for when the email has a '+' in it
		email = email ? email.replace(' ', '+') : null;

		try {
			if (oobCode !== null || apiKey !== null) {
				const response = await fetch(
					`https://www.googleapis.com/identitytoolkit/v3/relyingparty/resetPassword?key=${apiKey}`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							oobCode,
							newPassword: password,
						}),
					}
				);

				if (response.status === 400) {
					setMessageState({
						message: `Your request to set your password has expired or the link has already been used. Click <a href="/reset-password" style="color: var(--body-text)">here</a> send yourself an email to reset your password.`,
						messageColor: 'var(--red-200)',
						hasError: true,
					});
					return;
				}
			} else {
				setMessageState({
					message: `Your request to set your password has expired or the link has already been used. Click <a href="/reset-password" style="color: var(--body-text)">here</a> send yourself an email to reset your password.`,
					messageColor: 'var(--red-200)',
					hasError: true,
				});
				return;
			}
			if (email !== null) {
				const auth = getAuth();
				await signInWithEmailAndPassword(auth, email, password);
				navigate('/dashboard/courses');
			}
		} catch (error) {
			setMessageState({
				message: 'Something went wrong. Please try again.',
				messageColor: 'var(--red-200)',
				hasError: true,
			});
		}
	};

	return (
		<StyledBG>
			<StyledFeatherBackgroundTop />
			<StyledFeatherBackgroundBottom />
			<img src={`${logo}`} alt="Elevate logo" className="logo" />
			<StyledForm onSubmit={handleSubmit(onSubmit)} data-testid="form">
				<StyledP mb="var(--spacing-4)">
					<strong>Set your password below.</strong> You will be redirected once your
					password is successfully set.
				</StyledP>
				<PasswordInput
					labelText="New Password"
					registerOptions={register('password', {
						required: 'Password is required',
						minLength: {
							value: 8,
							message: 'Email must be at least 8 characters',
						},
					})}
					errors={errors}
				/>
				<PasswordInput
					labelText="Confirm Password"
					registerOptions={register('passwordConfirmation', {
						required: 'Password is required',
						validate: (val: string) => {
							if (watch('password') !== val) {
								return 'Your passwords do no match.';
							}
							return true;
						},
						minLength: {
							value: 8,
							message: 'Email must be at least 8 characters',
						},
					})}
					errors={errors}
				/>
				<StyledPrimaryButton type="submit" disabled={isSubmitting}>
					Set Password
				</StyledPrimaryButton>
				{messageState.message !== '' && (
					<BoxedIconMessage
						message={messageState.message}
						messageColor={messageState.messageColor}
						hasError={messageState.hasError}
					/>
				)}
			</StyledForm>
			<ToastNotification />
		</StyledBG>
	);
};

export default SetPassword;
