import { useCallback, useState } from 'react';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';

interface ConfirmationDialogProps {
	message: string;
	onConfirm: (data: any) => void;
	onCancel: () => void;
}

// ?? Implement a different confirmation dialog
export default function useConfirmationDialog({
	message,
	onConfirm,
	onCancel,
}: ConfirmationDialogProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [currentData, setCurrentData] = useState<any>(null);
	const onOpen = (passedData: unknown) => {
		setIsOpen(true);
		setCurrentData(passedData);
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
		onCancel();
	}, [onCancel]);

	const Dialog = useCallback(
		() => (
			<>
				<ConfirmModal
					modalActive={isOpen}
					onConfirm={() => onConfirm(currentData)}
					onCancel={onClose}
					triggerModal={() => setIsOpen(false)}
					selectedData=""
					message={message}
				/>
			</>
		),
		[message, isOpen, onConfirm, currentData, onClose]
	);

	return {
		Dialog,
		onOpen,
	};
}
