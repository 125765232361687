import { useState } from 'react';
import ReactModal from 'react-modal';
import * as Sentry from '@sentry/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import debounce from 'just-debounce-it';
import sortBy from 'just-sort-by';
import imageCompression from 'browser-image-compression';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ColorPicker from '@/components/ColorPicker';
import {
	GlobalModalStyle,
	StyledPrimaryButton,
	StyledHR,
	StyledFileInput,
	StyledInput,
} from '@/Shared/StyledElements';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import { StyledH3 } from '@/Shared/Typography/typography';
import SimpleEditor from '@/components/TipTapEditor/SimpleEditor';
import { FormErrorMessage } from '@/components/FormErrorMessage';
import { renameAndDestructure, prefix } from '@/utils';
import { GetAllDisciplinesQuery } from '@/graphql/graphql';
import {
	modalStyles,
	StyledFooterBody,
	StyledModalBody,
	StyledModalHeader,
	StyledCloseIcon,
	StyledImagePreviewContainer,
} from './DisciplineListStyles';
import { updateDisciplines, createDiscipline } from './DisciplinesContainers';

interface DisciplineModalProps {
	triggerModal(): void;
	modalData: {
		isActive: boolean;
		isEditing: boolean;
		discipline: GetAllDisciplinesQuery['dev_disciplines'][0] | null;
	};
	allDisciplines: GetAllDisciplinesQuery['dev_disciplines'] | undefined;
}

interface FormValues {
	disciplineName: string;
	color: string;
	disciplineImage: File[];
}

const DisciplineListModal = ({ modalData, triggerModal, allDisciplines }: DisciplineModalProps) => {
	const { isEditing, discipline, isActive } = modalData;
	const [selectedColor, setSelectedColor] = useState(
		modalData.discipline?.color ?? 'var(--elevate-primary)'
	);
	const [isSaving, setIsSaving] = useState(false);
	const [imagePreview, setImagePreview] = useState<any>(discipline?.image);
	const {
		handleSubmit,
		register,
		formState: { errors },
		setValue,
		control,
		getValues,
	} = useForm<FormValues>();
	const [current, setCurrentPhilosophy] = useState(discipline?.philosophy);
	const queryClient = useQueryClient();
	const { mutate: disciplineUpdateMutation, status: updateStatus } = useMutation({
		mutationFn: updateDisciplines,
		onSuccess: (returnValue) => {
			showSuccessToast('Discipline Updated');
			setIsSaving(false);
			// update query cache
			const globalSettingObj = queryClient.getQueryData([
				'get-settings-divisions-disciplines',
			]) as SettingsDivisionDiscipline;
			const previousDisciplineData = queryClient.getQueryData([
				'get-all-disciplines',
			]) as GetAllDisciplinesQuery['dev_disciplines'];
			const idx = previousDisciplineData.findIndex(
				(discipline) => discipline.id === returnValue?.id
			);
			const clone = [...previousDisciplineData];
			if (returnValue !== undefined) {
				clone[idx] = returnValue;
				queryClient.setQueryData(['get-all-disciplines'], clone);
				queryClient.invalidateQueries({
					queryKey: ['get-course-alignment'],
				});
				queryClient.invalidateQueries({
					queryKey: ['get-settings-divisions-disciplines'],
				});

				queryClient.setQueryData(['get-settings-divisions-disciplines'], {
					...globalSettingObj,
					disciplines: clone,
				});
			}
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});
	const { mutate: disciplineCreateMutation, status: createStatus } = useMutation({
		mutationFn: createDiscipline,
		onSuccess: (data: { insert_disciplines_one: Discipline }) => {
			const { insert_disciplines_one: createdDiscipline } = renameAndDestructure(
				data,
				prefix
			);
			showSuccessToast('Discipline Added');
			setIsSaving(false);
			// update query cache
			const previousDisciplineData = queryClient.getQueryData([
				'get-all-disciplines',
			]) as Discipline[];

			const updatedCache = sortBy([createdDiscipline, ...previousDisciplineData], 'name');
			queryClient.setQueryData(['get-all-disciplines'], updatedCache);
			queryClient.invalidateQueries({
				queryKey: ['get-settings-divisions-disciplines'],
			});
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const handleChange = debounce((html: string) => {
		setCurrentPhilosophy(html);
	}, 500);

	// eslint-disable-next-line arrow-body-style
	const blobToDataURL = (blob: Blob) => {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = () => reject(reader.error);
			reader.onabort = () => reject(new Error('Read aborted'));
			reader.readAsDataURL(blob);
		});
	};

	const getImageUrl = async (file: File) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};
		const compressedFile = await imageCompression(file, options);
		const dataUri = await blobToDataURL(compressedFile);
		const firebaseFunction = getFunctions();
		const uploadImage = httpsCallable(firebaseFunction, 'uploadCloudinaryImage');

		try {
			const res = await uploadImage({
				file: dataUri,
				folder: `elevate-${import.meta.env.VITE_CLOUDINARY_PRESET}`,
			});
			// const [start, end] = (res as any).data?.secure_url.split('upload');
			const results = (res as any).data?.secure_url.split('upload');
			const start = results[0];
			const end = results[1];
			return `${start}upload/c_scale,w_375${end}`;
		} catch (error) {
			return 'error';
		}
	};

	const removePreviousImage = () => {
		const imageRef = discipline?.image;
		const pattern = `(elevate-${
			import.meta.env.VITE_CLOUDINARY_PRESET
		}|elevate-sandbox)/(.*).(png|jpg|jpeg|avif)`;
		const regex = new RegExp(pattern);
		const imageId = imageRef?.match(regex)?.[2];
		const firebaseFunction = getFunctions();
		const removeImage = httpsCallable(firebaseFunction, 'deleteCloudinaryImage');
		removeImage(`elevate-${import.meta.env.VITE_CLOUDINARY_PRESET}/${imageId}`).catch(
			(error) => {
				Sentry.captureException(error);
			}
		);
	};

	const handleRemoveImage = () => {
		setImagePreview(null);
		removePreviousImage();
		setValue('disciplineImage', []);
		disciplineUpdateMutation({
			id: discipline?.id ?? '',
			philosophy: current ?? '',
			name: getValues('disciplineName').trim(),
			color: selectedColor,
			image: '',
			previousState: discipline,
		});
	};

	const onSubmit: SubmitHandler<FormValues> = async (formData) => {
		const file = formData.disciplineImage?.[0];

		let imageUrl = '';
		if (file) {
			setIsSaving(true);
			imageUrl = await getImageUrl(file);
			removePreviousImage();
		}

		if (isEditing) {
			disciplineUpdateMutation({
				id: discipline?.id ?? '',
				philosophy: current ?? '',
				name: formData.disciplineName.trim(),
				color: formData.color,
				image: imageUrl,
				previousState: discipline,
			});
			return;
		}
		disciplineCreateMutation({
			philosophy: current ?? '',
			name: formData.disciplineName.trim(),
			color: formData.color,
			image: imageUrl,
		});
	};

	const onPhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e?.target?.files?.[0];
		if (reader !== undefined && file !== undefined) {
			reader.onloadend = () => {
				setImagePreview(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	// TODO: move to higher level utils file
	const isUnique = (value: string) => {
		const isPresent = allDisciplines?.some(
			(discipline) => discipline?.name?.toLowerCase() === value.toLowerCase()
		);
		return isPresent;
	};

	const renderButtonText = () => {
		if (isSaving) {
			return 'Saving...';
		}
		return isEditing ? 'Save' : 'Add Discipline';
	};

	ReactModal.setAppElement('#root');

	return (
		<ReactModal
			closeTimeoutMS={100}
			isOpen={isActive}
			shouldCloseOnEsc={false}
			onRequestClose={triggerModal}
			contentLabel="Discipline Modal"
			style={modalStyles}>
			<GlobalModalStyle />
			<StyledModalHeader>
				<StyledCloseIcon passedEvent={triggerModal} />
				{isEditing ? (
					<StyledH3 mb="0" mr="var(--spacing-4)">
						Editing: {discipline?.name}
					</StyledH3>
				) : (
					<StyledH3 mb="0" mr="var(--spacing-4)">
						Adding New Discipline{' '}
					</StyledH3>
				)}
			</StyledModalHeader>
			<StyledModalBody>
				<form onSubmit={handleSubmit(onSubmit)}>
					<StyledInput
						className="input-container"
						type="text"
						defaultValue={discipline?.name}
						placeholder="Discipline Name"
						{...register('disciplineName', {
							validate: (value) => {
								if (value.trim() === '') {
									return 'Discipline Name is Required';
								}
								if (isEditing) {
									return true;
								}
								if (isUnique(value.trim())) {
									return 'This discipline already exists.';
								}

								return true;
							},
						})}
					/>
					<FormErrorMessage
						className="error-message"
						isShowing={!!errors?.disciplineName}
						message={errors.disciplineName?.message}
					/>
					<Controller
						control={control}
						name="color"
						defaultValue={selectedColor}
						render={({ field }) => {
							return (
								<ColorPicker
									onChange={(color) => {
										setSelectedColor(field.value);
										field.onChange(color.hex);
									}}
									color={selectedColor}
								/>
							);
						}}
					/>
					{/* <ColorPicker onChange={handleColorChange} color={selectedColor} /> */}
					{imagePreview && (
						<StyledImagePreviewContainer>
							<StyledCloseIcon passedEvent={handleRemoveImage} />
							<img src={imagePreview} alt="" />
						</StyledImagePreviewContainer>
					)}
					{!imagePreview && (
						<div className="preview-wrapper">
							<img
								src="https://via.placeholder.com/300x200?text=No Image Selected"
								alt=""
							/>
						</div>
					)}
					<StyledFileInput
						style={{ display: 'block' }}
						type="file"
						{...register('disciplineImage')}
						name="disciplineImage"
						accept=".jpeg, .png, .jpg, .avif"
						onChange={onPhotoUpload}
					/>
					<StyledHR />
					<SimpleEditor
						content={current ?? ''}
						onChange={handleChange}
						placeholder="Add discipline philosophy..."
						isEditable
					/>
				</form>
			</StyledModalBody>
			<StyledFooterBody>
				<StyledPrimaryButton
					disabled={[createStatus, updateStatus].includes('pending') || isSaving}
					type="submit"
					onClick={handleSubmit(onSubmit)}>
					{renderButtonText()}
				</StyledPrimaryButton>
			</StyledFooterBody>
		</ReactModal>
	);
};

export default DisciplineListModal;
