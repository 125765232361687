import { useState, useEffect } from 'react';
import imageCompression from 'browser-image-compression';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { captureException } from '@sentry/react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
	StyledPrimaryButton,
	StyledHR,
	StyledFileInput,
	StyledInput,
} from '@/Shared/StyledElements';
import { StyledH4 } from '@/Shared/Typography/typography';
import Loader from '@/components/Loader';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import ColorPicker from '@/components/ColorPicker';
import SimpleEditor from '@/components/TipTapEditor/SimpleEditor';
import { StyledSettings } from './Settings.Styles';
import { updateSettings, useGetSiteSettings } from './SettingsContainer';

interface FormValues {
	statement: string;
	statementTitle: string;
	bannerImage: File[];
	logoImage: File[];
	color: string;
}

const Settings = () => {
	const [isSaving, setIsSaving] = useState(false);
	const queryClient = useQueryClient();
	const { register, handleSubmit, control } = useForm<FormValues>();
	const { data: settings, isLoading } = useGetSiteSettings();
	const [selectedColor, setSelectedColor] = useState(settings?.primary_color ?? '');
	const [bannerPreview, setBannerPreview] = useState(settings?.banner ?? '');
	const [logoPreview, setLogoPreview] = useState(settings?.logo ?? '');
	const bannerSizeRef = 'c_scale,w_1800';
	const logoSizeRef = 'c_scale,w_600';

	const { mutate: settingsMutation } = useMutation({
		mutationFn: updateSettings,
		onSuccess: (settingsObj) => {
			showSuccessToast('Settings Updated');
			setIsSaving(false);
			// update query cache
			const globalSettingObj = queryClient.getQueryData([
				'get-settings-divisions-disciplines',
			]) as SettingsDivisionDiscipline;

			queryClient.setQueryData(['get-site-settings'], {
				id: settingsObj?.id,
				statement: settingsObj?.statement,
				statement_title: settingsObj?.statement_title,
				primary_color: settingsObj?.primary_color,
				banner: settingsObj?.banner,
				logo: settingsObj?.logo,
			});

			queryClient.setQueryData(['get-settings-divisions-disciplines'], {
				...globalSettingObj,
				siteSettings: {
					id: settingsObj?.id,
					statement: settingsObj?.statement,
					statement_title: settingsObj?.statement_title,
					primary_color: settingsObj?.primary_color,
					banner: settingsObj?.banner,
					logo: settingsObj?.logo,
				},
			});
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const removePreviousImage = (type: 'banner' | 'logo') => {
		const imageRef = type === 'banner' ? settings?.banner : settings?.logo;
		const pattern = `(elevate-${
			import.meta.env.VITE_CLOUDINARY_PRESET
		}|elevate-sandbox)/(.*).(png|jpg|jpeg|avif)`;
		const regex = new RegExp(pattern);
		const imageId = imageRef?.match(regex)?.[2];
		const firebaseFunction = getFunctions();
		const removeImage = httpsCallable(firebaseFunction, 'deleteCloudinaryImage');
		removeImage(`elevate-${import.meta.env.VITE_CLOUDINARY_PRESET}/${imageId}`).catch(
			(error) => {
				if (import.meta.env.MODE !== 'development') {
					captureException(error);
				}
			}
		);
	};

	const onPhotoUpload = (e: React.ChangeEvent<HTMLInputElement>, type: 'banner' | 'logo') => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e?.target?.files?.[0];

		if (reader !== undefined && file !== undefined) {
			reader.onloadend = () => {
				if (type === 'banner') {
					setBannerPreview(`${reader.result}`);
				}
				if (type === 'logo') {
					setLogoPreview(`${reader.result}`);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	// eslint-disable-next-line arrow-body-style
	const blobToDataURL = (blob: Blob) => {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = () => reject(reader.error);
			reader.onabort = () => reject(new Error('Read aborted'));
			reader.readAsDataURL(blob);
		});
	};

	const getImageUrl = async (file: File, size: string) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};
		const compressedFile = await imageCompression(file, options);
		const dataUri = await blobToDataURL(compressedFile);
		const firebaseFunction = getFunctions();
		const uploadImage = httpsCallable(firebaseFunction, 'uploadCloudinaryImage');

		try {
			const res = await uploadImage({
				file: dataUri,
				folder: `elevate-${import.meta.env.VITE_CLOUDINARY_PRESET}`,
			});
			const results = (res as any).data?.secure_url.split('upload');
			const start = results[0];
			const end = results[1];
			return `${start}upload/${size}${end}`;
		} catch (error) {
			if (import.meta.env.MODE !== 'development') {
				captureException(error);
			}
			return 'error';
		}
	};

	const onSubmit: SubmitHandler<FormValues> = async (formData) => {
		setIsSaving(true);
		const { statement, statementTitle, bannerImage, logoImage, color } = formData;
		let bannerUrl = '';
		let logoUrl = '';

		if (bannerImage?.[0]) {
			bannerUrl = await getImageUrl(bannerImage[0], bannerSizeRef);
			removePreviousImage('banner');
		}
		if (logoImage?.[0]) {
			logoUrl = await getImageUrl(logoImage[0], logoSizeRef);
			removePreviousImage('logo');
		}
		if ([bannerUrl, logoUrl].includes('error')) {
			setIsSaving(false);
			showErrorToast('Oh no, something went wrong... Please try again.');
			return;
		}

		settingsMutation({
			id: settings?.id ?? '',
			statement,
			statementTitle,
			primaryColor: color,
			banner: bannerUrl || bannerPreview,
			logo: logoUrl || logoPreview,
		});
	};

	useEffect(() => {
		if (settings?.primary_color) {
			setSelectedColor(settings?.primary_color);
		}
		if (settings?.banner) {
			setBannerPreview(settings?.banner);
		}
		if (settings?.logo) {
			setLogoPreview(settings?.logo);
		}
	}, [settings]);

	if (isLoading) return <Loader />;

	return (
		<StyledSettings>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledH4 mb="var(--spacing-4)">School Statement</StyledH4>
				<div>
					<StyledInput
						type="text"
						defaultValue={settings?.statement_title}
						placeholder="Statement Title (i.e. Our Mission)"
						{...register('statementTitle')}
					/>
				</div>
				<Controller
					control={control}
					name="statement"
					defaultValue={settings?.statement}
					render={({ field }) => (
						<SimpleEditor
							content={settings?.statement ?? ''}
							onChange={field.onChange}
							placeholder="School Statement..."
						/>
					)}
				/>
				<StyledHR />
				<StyledH4 mb="var(--spacing-4)">School Color</StyledH4>
				{selectedColor && (
					<Controller
						control={control}
						name="color"
						defaultValue={selectedColor}
						render={({ field }) => {
							return (
								<ColorPicker
									onChange={(color) => {
										setSelectedColor(color.hex);
										field.onChange(color.hex);
									}}
									color={selectedColor}
								/>
							);
						}}
					/>
				)}
				<StyledHR />
				<StyledH4 mb="var(--spacing-4)">Banner Image</StyledH4>
				{bannerPreview && (
					<div
						className="banner-preview"
						style={{ backgroundImage: `url(${bannerPreview})` }}
					/>
				)}
				{!bannerPreview && (
					<img
						className="placeholder-image"
						src="https://via.placeholder.com/1000x200/?text=No Image Selected"
						alt="placeholder"
					/>
				)}
				<StyledFileInput
					type="file"
					{...register('bannerImage')}
					accept=".jpeg, .png, .jpg, .avif"
					onChange={(e) => onPhotoUpload(e, 'banner')}
				/>
				<StyledHR />
				<StyledH4 mb="var(--spacing-4)">School Logo</StyledH4>
				{logoPreview && <img src={logoPreview} alt="Logo" className="logo-preview" />}
				{!logoPreview && (
					<img
						className="placeholder-image"
						src="https://via.placeholder.com/300x150/?text=No Image Selected"
						alt="placeholder"
					/>
				)}
				<StyledFileInput
					type="file"
					{...register('logoImage')}
					accept=".jpeg, .png, .jpg, .avif"
					onChange={(e) => onPhotoUpload(e, 'logo')}
				/>
				<StyledHR />
				<StyledPrimaryButton type="submit" disabled={isSaving}>
					{isSaving ? 'Saving Changes...' : 'Save Changes'}
				</StyledPrimaryButton>
			</form>
		</StyledSettings>
	);
};

export default Settings;
