import { gql, GraphQLClient } from 'graphql-request';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { prefix, renameAndDestructure } from '@/utils';
import { GetCourseQuery, GetSiteSettingsAndDisciplinesQuery } from '@/graphql/graphql';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export const getMapCourse = async ({ queryKey }: QueryFunctionContext<[string, string]>) => {
	const [, currentCourseId] = queryKey;

	graphQLClient.setHeader('content-type', `application/json`);

	const courseQuery = gql`
			query GetCourse($id: uuid!) {
					${prefix}courses_by_pk(course_id: $id) {
							course_name
							course_id
							course_description
							course_resource_info
							course_extras
							course_prereq
							last_updated
							courses_outcomes(order_by: { outcome: { outcome_order: asc } }) {
									courses_outcomes_id
									outcome {
											outcome_order
											outcome_text
											outcome_id
									}
							}
							courses_resources(order_by: { resource: { resource_type: asc } }) {
									course_resources_id
									resource {
											resource_author
											resource_detail
											resource_isbn
											resource_id
											resource_title
											resource_type
									}
							}
							courses_focuses(order_by: { focus: { focus_order: asc, focus_type: desc } }) {
									courses_focuses_id
									focus {
											focus_title
											focus_type
											focus_order
											focus_id
											focuses_skills {
													focuses_skills_id
													skill {
															skill_id
															skill_text
															skill_type
													}
											}
									}
							}
					}
			}
	`;

	if (!currentCourseId) return null;

	const result = await graphQLClient.request<GetCourseQuery>(courseQuery, {
		id: currentCourseId,
	});

	const { courses_by_pk: course } = renameAndDestructure(result, prefix) as {
		courses_by_pk: GetCourseQuery['dev_courses_by_pk'];
	};

	return course;
};

export const useSiteSettings = () => {
	graphQLClient.setHeader('content-type', `application/json`);

	return useQuery({
		queryKey: ['get-public-map-site-settings'],

		queryFn: async () => {
			const result = await graphQLClient.request<GetSiteSettingsAndDisciplinesQuery>(
				gql`
					query GetSiteSettingsAndDisciplines {
						${prefix}site_settings {
							id
							statement
							statement_title
							primary_color
							banner
						}
						${prefix}disciplines(order_by: { name: asc }) {
							id
							color
							name
						}
					}
				`
			);

			const { site_settings, disciplines } = renameAndDestructure(result, prefix) as {
				site_settings: GetSiteSettingsAndDisciplinesQuery['dev_site_settings'];
				disciplines: GetSiteSettingsAndDisciplinesQuery['dev_disciplines'];
			};

			return { meta: site_settings[0], disciplines };
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
};
