interface PanelProps {
	title: string;
	children: JSX.Element;
}

const Panel = ({ ...props }: PanelProps) => {
	const { children } = props;
	return children;
};

export default Panel;
