/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import ReactModal from 'react-modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { gql, GraphQLClient } from 'graphql-request';
import AsyncSelect from 'react-select/async';

import { GlobalModalStyle, StyledInput } from '@/Shared/StyledElements';
import { StyledH3, StyledP, StyledSubHeader } from '@/Shared/Typography/typography';
import { userDefinedRoles } from '@/Shared/Data/StaticData';
import { useUserRole } from '@/hooks/useAuth';
import { showSuccessToast, showErrorToast } from '@/components/ToastNotification';
import CourseTags from '@/components/CourseTags';
import SaveButton from '@/components/SaveButton/SaveButton';
import { FormErrorMessage } from '@/components/FormErrorMessage';
import { prefix, renameAndDestructure, themeSelectStyles } from '@/utils';
import {
	AllCoursesQuery,
	GetDeptUserQuery,
	GetUsersQuery,
	UpdateCurrentUserMutation,
} from '@/graphql/graphql';
import UserModalPanel from '../UserModalPanel/UsersModalPanel';
import { isCourseUserEntity } from '../UserInterfaces';
import {
	StyledModalHeader,
	StyledModalBody,
	StyledFooterBody,
	StyledForm,
	StyledCourseContainer,
	modalStyles,
	StyledCloseIcon,
} from './UserModalStyles';

interface UserModalProps {
	triggerModal(): void;
	modalActive: boolean;
	modalData: GetUsersQuery['dev_users'][0];
	deptChairList: GetDeptUserQuery['dev_users'];
}

interface FormValues {
	firstName: string;
	lastName: string;
	deptChairId: string;
	role: string;
}

const UserModal = ({ modalActive, triggerModal, modalData, deptChairList }: UserModalProps) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<FormValues>();
	const isMutatingUser = useIsMutating({
		mutationKey: ['user-info-update'],
	});
	const {
		dept_chair: deptChair,
		user_first: firstName,
		user_last: lastName,
		user_id: userId,
		user_role: userRole,
		courses_users: initialUserCourses,
	} = modalData;
	const [userCourses, setUserCourses] =
		useState<NonNullable<GetUsersQuery['dev_users']>[0]['courses_users']>();
	const { data: userRoleData } = useUserRole();

	useEffect(() => {
		const abortController = new AbortController();
		setUserCourses(initialUserCourses);
		return () => {
			abortController.abort();
		};
	}, [initialUserCourses, userRole]);

	const createDeptList = (): { label: string; value: string; id: string }[] => {
		const formattedList = deptChairList
			.filter((c) => c.user_id !== userId)
			.map((c) => ({
				label: `${c.user_first ?? ''} ${c.user_last ?? ''}`,
				value: `${c.user_first ?? ''} ${c.user_last ?? ''}`,
				id: c.user_firebase_id,
			}));

		return formattedList;
	};

	interface UpdatedUserOption {
		userCourses:
			| Pick<AllCoursesQuery['dev_courses'][0], 'course_id' | 'course_name'>[]
			| undefined;
		currentUserCourses: CourseUserEntity[];
		userId: string;
		firstName: string;
		lastName: string;
		role: string;
		deptChair: string;
	}

	const updateUser = async (variables: UpdatedUserOption) => {
		const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
		const auth = getAuth();
		const token = await auth.currentUser?.getIdToken();

		// TODO: update role connections to avoid using delay
		function delay() {
			// eslint-disable-next-line no-promise-executor-return
			return new Promise((resolve) => setTimeout(resolve, 500));
		}

		const graphQLClient = new GraphQLClient(endpoint, {
			headers: {
				Authorization: `Bearer ${token}`,
				'x-hasura-role': userRoleData?.user_role ?? '',
			},
		});

		const { currentUserCourses, userCourses, ...userInfo } = variables;

		const removeCourseMutation = gql`
			mutation RemoveUserCourse($courseId: uuid!) {
				${prefix}delete_courses_users(where: { courses_users_id: { _eq: $courseId } }) {
					returning {
						user_id
					}
				}
			}
		`;

		const removedCourses =
			initialUserCourses?.filter(
				(c) => !userCourses?.some((b) => b.course_id === c.course_id)
			) ?? [];

		if (removedCourses.length !== 0) {
			for (let i = 0; i < removedCourses.length; i += 1) {
				const courseId = currentUserCourses.find(
					(c: CourseUserEntity) => c.course_id === removedCourses[i].course_id
				)?.courses_users_id;

				if (courseId) {
					await graphQLClient.request(removeCourseMutation, { courseId });
				}
				if (i % 5 === 0) {
					await delay();
				}
			}
		}

		const addCourseMutation = gql`
			mutation AddUserCourse($courseId: uuid!, $userId: uuid!) {
				${prefix}insert_courses_users(objects: { course_id: $courseId, user_id: $userId }) {
					affected_rows
				}
			}
		`;

		if (userCourses?.length !== 0 && userCourses) {
			const newCourses = userCourses.filter((c) => {
				if (modalData.courses_users?.some((cu) => c.course_id === cu.course_id)) {
					return false;
				}
				return c;
			});

			if (newCourses.length !== 0) {
				for (let i = 0; i < newCourses.length; i += 1) {
					const courseId = newCourses[i].course_id;
					const userId = modalData.user_id;

					if (courseId) {
						await graphQLClient.request(addCourseMutation, {
							courseId,
							userId,
						});
					}
					if (i % 5 === 0) {
						await delay();
					}
				}
			}
		}

		const updateUserMutation = gql`
			mutation UpdateCurrentUser(
				$userId: uuid!
				$firstName: String
				$lastName: String
				$role: String
				$deptChair: String
			) {
				${prefix}update_users(
					where: { user_id: { _eq: $userId } }
					_set: {
						user_first: $firstName
						user_last: $lastName
						user_role: $role
						user_dept_chair: $deptChair
					}
				) {
					affected_rows
					returning {
						user_dept_chair
					}
				}
			}
		`;

		const data = await graphQLClient.request<UpdateCurrentUserMutation>(
			updateUserMutation,
			userInfo
		);
		const response = renameAndDestructure(data, prefix);
		return response;
	};

	const queryClient = useQueryClient();
	const { mutate: userMutate, status } = useMutation({
		mutationFn: updateUser,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get-users'],
			});
			queryClient.invalidateQueries({
				queryKey: ['get-dept-chairs'],
			});
			showSuccessToast('User Updated');
		},

		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const handleModalClose = () => {
		if (status !== 'pending') {
			triggerModal();
		}
	};

	const onSubmit: SubmitHandler<FormValues> = async (formData) => {
		const { firstName, lastName, deptChairId, role } = formData;
		const currentUserCourses = modalData.courses_users ?? [];

		if (userCourses) {
			userMutate({
				userCourses: userCourses as any,
				currentUserCourses: currentUserCourses as any,
				userId,
				firstName,
				lastName,
				role,
				deptChair: deptChairId ?? '',
			});
		}
	};

	ReactModal.setAppElement('#root');

	const setTagText = (c: any) => {
		if (isCourseUserEntity(c)) {
			return c.course?.course_name;
		}
		return c.course_name ?? '';
	};

	return (
		<ReactModal
			closeTimeoutMS={100}
			isOpen={modalActive}
			onRequestClose={handleModalClose}
			contentLabel="User Modal"
			style={modalStyles}>
			<GlobalModalStyle />
			<StyledModalHeader>
				<StyledCloseIcon passedEvent={triggerModal} />
				<StyledH3 mb="8px">
					Editing: {firstName} {lastName}
				</StyledH3>
			</StyledModalHeader>
			<StyledModalBody>
				<div className="input-column">
					<StyledSubHeader mb="16px">Assigned Courses</StyledSubHeader>
					<StyledCourseContainer>
						{!userCourses?.length && (
							<StyledP
								style={{
									display: 'inline-block',
									marginRight: 'var(--spacing-2)',
									minHeight: '33px',
								}}>
								No Courses assigned...
							</StyledP>
						)}
						{userCourses &&
							userCourses.map((course) => (
								<CourseTags
									style={{ marginRight: 'var(--spacing-2)' }}
									key={uuidv4()}
									text={setTagText(course)}
								/>
							))}
					</StyledCourseContainer>
					<StyledForm onSubmit={handleSubmit(onSubmit)}>
						<Controller
							control={control}
							name="role"
							defaultValue={userRole ?? ''}
							render={({ field }) => (
								<AsyncSelect
									styles={themeSelectStyles}
									className="select"
									placeholder="Select Role"
									defaultValue={{
										label: userRole,
										value: userRole,
									}}
									onChange={({ value }) => {
										field.onChange(value);
									}}
									isClearable={false}
									defaultOptions={userDefinedRoles}
									loadOptions={(inputValue) => {
										return new Promise((resolve) => {
											const array = userDefinedRoles;
											const filterResults = array.filter((x) =>
												x.value
													.toLowerCase()
													.includes(inputValue.toLowerCase())
											);
											resolve(filterResults);
										});
									}}
								/>
							)}
						/>
						<Controller
							control={control}
							name="deptChairId"
							render={({ field }) => (
								<AsyncSelect
									styles={themeSelectStyles}
									className="select"
									placeholder="Choose Dept. Chair"
									isClearable
									defaultValue={{
										label: `${deptChair?.user_first ?? 'Dept. Chair'} ${
											deptChair?.user_last ?? ''
										}`,
										value: `${deptChair?.user_first} ${deptChair?.user_last}`,
										id: `${deptChair?.user_first} ${deptChair?.user_last}`,
									}}
									loadOptions={(inputValue) => {
										return new Promise((resolve) => {
											const array = createDeptList();
											const filterResults = array.filter((x) =>
												x.value
													.toLowerCase()
													.includes(inputValue.toLowerCase())
											);
											resolve(filterResults);
										});
									}}
									defaultOptions={createDeptList()}
									onChange={(option) => {
										field.onChange(option?.id ?? '');
									}}
								/>
							)}
						/>
						<StyledInput
							type="text"
							defaultValue={firstName}
							placeholder="First Name"
							{...register('firstName', {
								required: 'First name is required',
							})}
							errors={errors}
						/>
						<FormErrorMessage
							className="error-message"
							isShowing={!!errors?.firstName}
							message={errors.firstName?.message}
						/>
						<StyledInput
							type="text"
							defaultValue={lastName}
							placeholder="Last Name"
							{...register('lastName', {
								required: 'Last name is required',
							})}
						/>
						<FormErrorMessage
							className="error-message"
							isShowing={!!errors?.lastName}
							message={errors.lastName?.message}
						/>
					</StyledForm>
				</div>
				<div className="panel-column">
					<UserModalPanel
						panelTitle="Course Selection"
						selectedCourses={userCourses ?? []}
						passedEvent={setUserCourses}
					/>
				</div>
			</StyledModalBody>
			<StyledFooterBody>
				<SaveButton isSaving={isMutatingUser} passedEvent={handleSubmit(onSubmit)} />
			</StyledFooterBody>
		</ReactModal>
	);
};

export default UserModal;
