import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { StyledSecondaryButton } from '@/Shared/StyledElements';
import { StyledP } from '@/Shared/Typography/typography';
import { DropResultEntity } from '@/types/source';
import { EditorType } from '@/Enums/enum';
import { useCourseEditStore } from '@/stores/courseEditStore';
import FadeIn from '@/components/animations/FadeIn';
import { StrictModeDroppable } from '@/components/StrictModeDroppable';
import DraggableOutcome from './DraggableOutcome';
import CourseDiff from '../CourseDiff';
import CourseEditContext from '../CourseEditContext';
import ApprovalCommentForm from '../CourseDescriptionTab/ApprovalCommentForm';

const CourseOutcomeTab = () => {
	const {
		courseData,
		updateOutcomeOrder,
		addOutcome,
		canEdit,
		submitCourse,
		courseDraftData,
		isApprovalMode,
	} = useContext(CourseEditContext);

	const { courses_outcomes: courseOutcome } = courseData;
	const { courses_outcomes: courseDraftOutcome } = courseDraftData;
	const { handleSubmit } = useForm({ shouldUnregister: true });

	const addNewOutcome = () => addOutcome();

	const renderHeader = () => {
		if (isApprovalMode && !courseDraftOutcome) {
			return <StyledP mb="32px">No outcomes are present.</StyledP>;
		}

		if (isApprovalMode && courseDraftOutcome && courseDraftOutcome?.length === 0) {
			return (
				<StyledP ml="16px" mb="32px">
					No outcomes are present.
				</StyledP>
			);
		}

		if (courseDraftOutcome?.length === 0) {
			return <StyledP mb="32px">Add the first course outcome.</StyledP>;
		}

		if (isApprovalMode) {
			return <></>;
		}

		return <></>;
	};

	const onSubmit = () => submitCourse();
	const { updateCommentTypes } = useCourseEditStore();

	// const onDraftSubmit = () => saveDraft();

	// const onDragStart = (result: DragStateEntity) => {
	// 	const element = document.body.querySelector(
	// 		`[data-rbd-draggable-id="${result.draggableId}"]`
	// 	) as HTMLElement;
	// 	element?.classList.add('dragging');
	// };

	const onDragEnd = (result: DropResultEntity) => {
		const outcomesClone = [...(courseDraftOutcome ?? [])];
		updateOutcomeOrder(outcomesClone, result);
	};

	useEffect(() => {
		updateCommentTypes([EditorType.OUTCOME]);
	}, [updateCommentTypes]);

	return (
		<>
			<FadeIn style={{ width: '100%' }}>
				<>
					{isApprovalMode && (
						<ApprovalCommentForm
							courseId={courseData.course_id}
							type={EditorType.OUTCOME}
						/>
					)}
					{renderHeader()}
					{!isApprovalMode && (
						<DragDropContext onDragEnd={onDragEnd}>
							{/* // TODO: remove form from here - it causes error due to nesting */}
							<form onSubmit={handleSubmit(onSubmit)}>
								<StrictModeDroppable droppableId={uuidv4()}>
									{(provided) => (
										<div ref={provided.innerRef} {...provided.droppableProps}>
											{courseDraftOutcome &&
												[...courseDraftOutcome]
													.sort(
														(
															a: CoursesOutcomesEntity,
															b: CoursesOutcomesEntity
														) =>
															a.outcome.outcome_order -
															b.outcome.outcome_order
													)
													.map(
														(
															outcome: CoursesOutcomesEntity,
															i: number
														) => {
															const key = outcome.courses_outcomes_id;
															const { outcome_id: outcomeId } =
																outcome.outcome;
															return (
																<DraggableOutcome
																	isDragDisabled={
																		isApprovalMode || !canEdit
																	}
																	key={key}
																	outcome={outcome}
																	draggableId={outcomeId}
																	index={i}
																/>
															);
														}
													)}
											{provided.placeholder}
										</div>
									)}
								</StrictModeDroppable>
							</form>
						</DragDropContext>
					)}
					{isApprovalMode &&
						courseDraftOutcome &&
						[...courseDraftOutcome]
							.sort(
								(a: CoursesOutcomesEntity, b: CoursesOutcomesEntity) =>
									a.outcome.outcome_order - b.outcome.outcome_order
							)
							.map((outcome: CoursesOutcomesEntity, i: number) => {
								const key = outcome.courses_outcomes_id;
								const { outcome_text: outcomeText } = outcome.outcome;
								return (
									<div className="diff-outcome-wrap" key={key}>
										<CourseDiff
											original={
												(courseOutcome?.[i] as CoursesOutcomesEntity)
													?.outcome.outcome_text
											}
											draft={outcomeText}
										/>
									</div>
								);
							})}
				</>
			</FadeIn>
			{canEdit && !isApprovalMode && (
				<StyledSecondaryButton type="button" size="small" onClick={addNewOutcome}>
					Add New Outcome
				</StyledSecondaryButton>
			)}
		</>
	);
};

export default CourseOutcomeTab;
