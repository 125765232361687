/* eslint-disable func-names */
/* eslint-disable guard-for-in */
import * as d3 from 'd3';
import React, {
	useCallback,
	useLayoutEffect,
	useRef,
	useState,
	useContext,
	useEffect,
} from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import GlobalContext from '@/context/GlobalContext';
import { StyledPrimaryButton, StyledSecondaryButton } from '@/Shared/StyledElements';
import CourseMapList from '@/components/CourseMap/CourseMapList/CourseMapList';
import MergeCourseMapList from '@/components/MergeCourseMapList';
import SwitchToggle from '@/components/SwitchToggle';
import {
	showErrorToast,
	showSuccessToast,
	ToastNotification,
} from '@/components/ToastNotification';
import FadeIn from '@/components/animations/FadeIn';
import Loader from '@/components/Loader';
import { useUserRole } from '@/hooks/useAuth';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import {
	StyledMap,
	StyledActions,
	StyledNotification,
} from '@/components/CourseMap/CourseMapStyles';
import {
	useMapUpdateFrag,
	useGetCourseMap,
	useDeleteCourseFrag,
} from '@/components/CourseMap/CourseMapContainers';
import {
	click,
	collapseTree,
	createCourseMapPaths,
	createPanLimits,
	diagonal,
	isEmpty,
	rectNode,
	wrap,
	wrapDiscipline,
} from '@/components/CourseMap/course-utils';
import {
	CourseNodeBase,
	CourseNodeEntity,
	MapMargin,
	NodeEntity,
	FormSubmit,
} from '@/components/CourseMap/coursemap.types';
import { createEditIcons } from '@/components/CourseMap/course-elements';
import MapButtonControls from '@/components/CourseMap/MapButtonControls';
import PrivacyTagLine from '@/components/PrivacyTagLine';
import useParentMapStore from '@/stores/parentMapStore';

interface CourseMapProps {
	isAdmin?: boolean;
	passedEvent?: (a?: any) => void;
}

d3.selection.prototype.conditionalTransition = function (isAdmin: boolean, duration: number) {
	return !isAdmin ? this.transition().duration(duration) : this;
};

const CourseMap = ({ isAdmin = true, passedEvent }: CourseMapProps) => {
	const svgChartRef = useRef<HTMLDivElement>(null);
	const [treeData, setTreeData] = useState<CourseNodeBase>({
		name: `${import.meta.env.VITE_ORG}`,
		id: 'root',
		tracker: 'root',
		children: [],
	});

	const { setCourseCoordsArray, duration, zoom, d3InstanceRef } = useParentMapStore();
	const { disciplines: disciplineData, siteSettings } = useContext(GlobalContext);

	const treeRef = useRef({});
	const svgRef = React.useRef(null);
	const [isMenuActive, setIsMenuActive] = useState(false);
	const [isMapAltered, setIsMapAltered] = useState(false);
	const [isParentMenuActive, setParentMenuActive] = useState(false);
	const [isAddingCourse, setAddingCourse] = useState(true);
	const { data: userRoleData } = useUserRole();
	const [isConfirming, setIsConfirming] = useState(false);
	const [currentCourse, setCurrentCourse] = useState<CourseListItem>({
		course_discipline: '',
		course_division: '',
		course_id: '',
		course_name: '',
	});
	const queryClient = useQueryClient();

	const { mutate: mapMutation } = useMutation({
		mutationFn: useMapUpdateFrag,
		onSuccess: () => {
			showSuccessToast('Course Map Updated');
			setIsMapAltered(false);
			queryClient.invalidateQueries({
				queryKey: ['get-course-alignment'],
			});
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const saveMapChanges = () => {
		mapMutation({ map: JSON.stringify(treeData) });
	};

	const { isLoading, isFetched, data } = useQuery({
		queryKey: ['get-course-map'],
		queryFn: useGetCourseMap,
		staleTime: Infinity,
		refetchOnMount: 'always',
	});

	useEffect(() => {
		if (data) {
			treeRef.current = data;
			if (isEmpty(data)) {
				setTreeData({
					name: `${import.meta.env.VITE_ORG}`,
					id: `${import.meta.env.VITE_ORG?.replace(/\s/g, '')}-0`,
					tracker: 'root',
					children: [],
				});
				return;
			}
			setTreeData(JSON.parse(data));
		}
	}, [data]);

	const { mutate: courseDeleteMutation } = useMutation({
		mutationFn: useDeleteCourseFrag,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get-admin-panel-courses'],
			});
			showSuccessToast('Course Deleted');
			setIsMapAltered(false);
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const rootNode = useCallback((node: d3.HierarchyNode<NodeEntity>) => {
		if (node) {
			const ancestors = node.ancestors();
			const path = ancestors.length - 1;

			return ancestors[path];
		}
		return null;
	}, []);

	const updateTreeData = useCallback((treeValue: CourseNodeBase) => {
		const currentTree = JSON.stringify(treeValue);
		const isChanged = treeRef.current !== currentTree;

		setTreeData({ ...treeValue });
		setIsMapAltered(isChanged);
	}, []);

	const findMatchingNode = useCallback((nodeRef: any, id: string): any => {
		let result = null;
		if (nodeRef instanceof Array) {
			for (let i = 0; i < nodeRef.length; i += 1) {
				if (result) {
					break;
				}
				result = findMatchingNode(nodeRef[i], id);
			}
		} else {
			// eslint-disable-next-line no-restricted-syntax
			for (const prop in nodeRef) {
				if (prop === 'children') {
					result = findMatchingNode(nodeRef[prop], id);
				}
				if (prop === 'data') {
					if (nodeRef[prop].tracker === id) {
						return nodeRef;
					}
				}
			}
		}
		return result;
	}, []);

	const removeCourse = useCallback(() => {
		const { course_id: id } = currentCourse;
		courseDeleteMutation({ id, userRole: userRoleData?.user_role ?? '' });
		const nodeTree = d3.hierarchy(treeData);
		const sn = findMatchingNode(nodeTree, id);
		const root = rootNode(sn) as any;

		if (!sn) return;

		if (sn?.children !== undefined) {
			const idx = sn.parent.data.children.findIndex(
				(child: CourseNodeBase) => child.tracker === sn.data.tracker
			);
			const newChildList = sn.parent.children.filter(
				(child: NodeEntity) => child.data.tracker !== sn.data.tracker
			);
			const newDataList = sn.parent.data.children.filter(
				(child: CourseNodeBase) => child.tracker !== sn.data.tracker
			);
			newChildList.splice(idx, 0, ...sn.children);
			newDataList.splice(idx, 0, ...sn.data.children);
			sn.parent.children = [...newChildList];
			sn.parent.data.children = [...newDataList];
			updateTreeData({ ...root?.data });
		} else {
			const newChildList = sn.parent.data.children.filter(
				(child: CourseNodeBase) => child.tracker !== sn.data.tracker
			);
			sn.parent.data.children = newChildList;
			updateTreeData({ ...root?.data });
		}
	}, [
		currentCourse,
		courseDeleteMutation,
		findMatchingNode,
		treeData,
		rootNode,
		updateTreeData,
		userRoleData?.user_role,
	]);

	const confirmDelete = useCallback((course: CourseListItem) => {
		setCurrentCourse(course);
		setIsConfirming(true);
	}, []);

	const checkTreeDiff = (a: FormSubmit) => {
		function eachRecursive(obj: any) {
			// eslint-disable-next-line no-restricted-syntax
			for (const k in obj) {
				// eslint-disable-next-line no-prototype-builtins
				if (obj.hasOwnProperty(k)) {
					if (typeof obj[k] === 'object' && obj[k] !== null) {
						eachRecursive(obj[k]);
					}

					if (obj[k] === a.courseId) {
						obj.discipline = a.disciplineEdits;
						obj.division = a.divisionEdits;
						obj.name = a.courseNameEdits;
						obj.grade = a.grade;
						obj.isAp = a.isAp;
						obj.isArchived = a.isArchived;
					}
				}
			}
		}

		eachRecursive(treeData);
		setTreeData({ ...treeData });

		const currentTree = JSON.stringify(treeData);
		const isChanged = treeRef.current !== currentTree;
		saveMapChanges();
		setIsMapAltered(isChanged);
	};

	const [selectedNode, setSelectedNode] = useState<NodeEntity>();

	const [newCourse, setNewCourse] = useState({
		data: {
			name: '',
		},
		depth: 0,
		height: 0,
		id: '',
		parent: {},
	});

	const zoomRef = useRef({
		k: null,
		x: null,
		y: null,
	} as any);

	const openPanel = () => {
		setIsMenuActive(!isMenuActive);
		if (isMenuActive) {
			d3.selectAll('.map-plus-icon').classed('active-icon', false);
		}
	};

	useLayoutEffect(() => {
		function renderMap() {
			const margin: MapMargin = { top: 40, right: 90, bottom: 50, left: 90 };
			const clientWidth = (svgChartRef.current?.clientWidth || 600) - 300;
			const clientHeight = svgChartRef.current?.clientHeight || 600;
			const width = clientWidth + 300 - margin.left - margin.right;
			const height = clientHeight - margin.top - margin.bottom;

			// reference svg element
			const svgEl: any = d3.select(svgRef.current);
			svgEl.selectAll('*').remove();

			const svg = svgEl
				.attr('width', '100%')
				.attr('height', '100%')
				.attr('class', 'course-map');
			d3InstanceRef.current = svg;
			const g = svg.append('g');

			// declares a tree layout and assigns the size
			const nodeSpacing = isAdmin ? 2.5 : 1.8;
			const treeMap = d3
				.tree()
				.nodeSize([1, clientHeight / nodeSpacing])
				.separation(() => rectNode.height * 1.3);

			// Assigns parent, children, height, depth
			const root: any = d3.hierarchy(treeData, function (d: CourseNodeBase) {
				return d.children;
			});
			root.x0 = height / 2;
			root.y0 = 0;

			// Collapse after the second level
			// save node amount prior to collapse
			const mapNodeLength = treeMap(root).descendants().length;
			setCourseCoordsArray(treeMap(root).descendants());
			// collapse tree if not admin
			if (!isAdmin) {
				root.children?.forEach(collapseTree);
			}

			const zoomed = (event: d3.D3ZoomEvent<SVGSVGElement, any>) => {
				zoomRef.current = event.transform;
				g.attr('transform', event.transform);
			};

			const currentK = zoomRef.current.k ?? 0.5;
			const currentX = zoomRef.current.x || clientWidth / 4.5;

			zoom.current = d3
				.zoom()
				.scaleExtent([0.15, 1])
				.translateExtent(createPanLimits(mapNodeLength, margin, clientWidth, isAdmin))
				.extent([
					[0, 0],
					[width, height],
				])
				.on('zoom', zoomed)
				.on('start', (e) => {
					if (e.sourceEvent) {
						d3.select(svgRef.current).classed('dragging', true);
					}
				})
				.on('end', () => {
					d3.select(svgRef.current).classed('dragging', false);
				});

			svg.call(zoom.current);

			// set initial zoom position
			let currentY = 0;
			if (isAdmin) {
				currentY = zoomRef.current.y ?? clientHeight / 2 + 102;
			} else {
				currentY = clientHeight / 2;
			}

			svg.call(
				zoom.current.transform,
				d3.zoomIdentity.translate(currentX, currentY).scale(currentK)
			);

			// clip path for svg image background
			const defs = svg.append('defs');
			const clipPath = defs.append('clipPath').attr('id', 'round-corner');
			clipPath
				.append('rect')
				.attr('x', 0)
				.attr('y', 0)
				.attr('width', rectNode.extWidth)
				.attr('height', rectNode.height)
				.attr('rx', '6');

			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			update(root);

			function generateRandomId(length = 8) {
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				let randomId = '';

				for (let i = 0; i < length; i += 1) {
					const randomIndex = Math.floor(Math.random() * characters.length);
					randomId += characters.charAt(randomIndex);
				}

				return randomId;
			}

			function update(source: NodeEntity) {
				const treeData = treeMap(root);
				// ?? better ID management
				// add uuid to each course for dom class reference purposes
				treeData.each((node: any) => {
					const id = generateRandomId();
					if (node.data.suppParent !== undefined) {
						node.data.suppParent = node.data.suppParent.map((parent: any) => {
							parent.id = `${parent.name?.replace(/\s/g, '')}-${node.depth - 1}`;
							return parent;
						});
					}

					node.id = id;
					node.data.id = id;
				});

				const multiParentNodes: any = [];

				// Compute the new tree layout
				const nodes = treeData.descendants();
				const links = treeData
					.descendants()
					.filter(
						(d: any) =>
							!d.data.isArchived || (d.data.isArchived && d.data.children?.length > 0)
					)
					.slice(1);
				// Normalize for fixed-depth.
				// changes x distance between nodes
				nodes.forEach(function (d: any) {
					if (d.data.isArchived && !d.set) {
						d.descendants().forEach((desc: any) => {
							desc.depth -= 1;
						});
					}
					if (!d.data.isArchived && d.set) {
						d.set = true;
						d.descendants().forEach((desc: any) => {
							desc.depth += 1;
						});
					}
					d.y = d.depth * 375;
				});

				// ****************** Nodes section ***************************

				// Update the nodes
				const node = g.selectAll('g.node').data(nodes, (d: NodeEntity) => d.data.tracker);
				// Enter any new modes at the parent's previous position.
				const nodeEnter = node
					.enter()
					.append('g')
					.attr('class', (d: NodeEntity) => {
						if (d.depth === 0) {
							return 'initial-node node';
						}
						return `node course-${d.data.tracker}`;
					})
					// used for the tutorial to highlight the course
					.attr('id', (d: NodeEntity, i: number) => {
						return `depth-${d.depth}-node-${i}`;
					})
					.attr(
						'transform',
						() => `translate(${source.y0},${(source.x0 ?? 0) - rectNode.width / 4 - 6})`
					)
					.on('click', (_: React.PointerEvent, d: NodeEntity) => {
						if (d.depth === 1) {
							click(d, update, isAdmin);
						}
					});

				nodeEnter
					.filter((d: any) => !d.data.name.includes('Spacer'))
					.append('rect')
					.attr('x', -11)
					.attr('y', -11)
					.attr('rx', 6)
					.attr('class', 'course-backdrop')
					.attr('fill', 'none')
					.attr('width', rectNode.extWidth + 20)
					.attr('height', rectNode.height + 20);

				// course card encompassing rect
				nodeEnter
					.filter((d: any) => !d.data.name.includes('Spacer'))
					.filter((d: any) => !d.data.isArchived)
					.append('rect')
					.attr('width', rectNode.extWidth)
					.attr('height', rectNode.height)
					.attr('fill', (d: NodeEntity) => {
						if (d.depth === 0) {
							return 'white';
						}
						if (d.depth === 1) {
							return d.data.color;
						}
						return 'rgba(197, 45, 45, 0.07)';
					})
					.attr('rx', 6)
					.on('click', (event: React.PointerEvent, sn: NodeEntity) => {
						if (
							!isAdmin &&
							passedEvent !== undefined &&
							(event.target as HTMLElement).classList.contains('view-btn')
						) {
							passedEvent(sn.data.tracker);
						}
					});

				// course card background image
				const imageHeight = rectNode.height - 20;
				nodeEnter
					.filter((d: any) => !d.data.isArchived)
					.append('image')
					.attr('height', (d: NodeEntity) =>
						d.depth === 0 ? imageHeight : rectNode.height
					)
					.attr('y', (d: NodeEntity) =>
						d.depth === 0 ? (rectNode.height - imageHeight) / 2 : 0
					)
					.attr('x', (d: NodeEntity) =>
						d.depth === 0 ? (rectNode.width - 200) / 2 + 25 : 0
					)
					.attr('width', (d: NodeEntity) => {
						if (d.depth === 0) {
							return rectNode.extWidth - 50;
						}
						return rectNode.extWidth;
					})
					.attr('preserveAspectRatio', (d: NodeEntity) =>
						d.depth === 0 ? 'xMidYMid' : 'xMinYMin slice'
					)
					.attr('clip-path', 'url(#round-corner)')
					.attr('xlink:href', (d: NodeEntity) => {
						if (d.depth === 0) {
							return siteSettings?.logo;
						}

						return disciplineData?.find(
							(discipline) => discipline.name === d.data.discipline
						)?.image;
					});

				// course card overlay
				const curve = 6;
				const widthExtWithCurve = rectNode.extWidth - curve * 2;
				const widthWithCurve = rectNode.width - curve;
				nodeEnter
					.filter((d: any) => !d.data.name.includes('Spacer'))
					.filter((d: any) => !d.data.isArchived)
					.append('path')
					.attr('d', (d: NodeEntity) => {
						if (d.depth <= 1) {
							return `M0 ${curve}q0-${curve} ${curve}-${curve}h${widthExtWithCurve}q${curve} 0 ${curve} ${curve}v98q0 ${curve}-${curve} ${curve}h-${widthExtWithCurve}q-${curve} 0-${curve}-${curve}z`;
						}
						return `M50, 0 h${widthWithCurve} q${curve},0 ${curve}, ${curve} v98 q0,${curve} -${curve},${curve} h-${
							widthWithCurve + 1
						} z`;
					})
					.attr('fill', (d: any) => {
						const hasImage = disciplineData?.find(
							(discipline) => discipline.name === d.data.discipline
						)?.image;
						if (d.depth > 1 && !hasImage) {
							const parentIdx = d.ancestors().length - 2;
							return d.ancestors()?.[parentIdx].data.color;
						}
						if (d.depth === 1 && !hasImage) {
							return d.data.color;
						}
						if (d.depth <= 1) {
							return 'rgba(0, 0, 0, 0.1)';
						}
						return 'rgba(124, 135, 142, 0.88)';
					})
					.attr('class', (d: NodeEntity) => {
						if (d.depth === 0) {
							return 'org-card';
						}
						if (d.depth === 1) {
							return 'discipline-card';
						}
						return 'course-card';
					});

				// view button background
				nodeEnter
					.filter((d: any) => d.depth > 1 && !d.data.name.includes('Spacer'))
					.filter((d: any) => !d.data.isArchived)
					.append('rect')
					.attr('width', 65)
					.attr('class', 'view-button')
					.attr('height', 30)
					.attr('fill', 'var(--org-color)')
					.attr('x', 176)
					.attr('y', 73)
					.attr('rx', 6)
					.on('click', (_: React.PointerEvent, sn: NodeEntity) => {
						if (!isAdmin && passedEvent !== undefined) {
							passedEvent(sn.data.tracker);
						}
					});

				// view button text
				nodeEnter
					.filter((d: any) => d.depth > 1 && !d.data.name.includes('Spacer'))
					.filter((d: any) => !d.data.isArchived)
					.append('text')
					.attr('class', 'view-button-text')
					.attr('y', 93)
					.attr('x', 189)
					.attr('fill', 'white')
					.style('font-size', '16px')
					.style('text-shadow', '-2px 2px 6px rgba(0, 0, 0, .7)')
					.text('View');

				// color bar within discipline cards
				const colorBarWidth = 30;
				nodeEnter
					.filter((d: any) => d.depth === 1)
					.filter((d: any) => !d.data.isArchived)
					.append('path')
					.attr(
						'd',
						`M-1 ${curve - 1}q0-${curve - 1} ${curve - 1}-${curve - 1}h${
							colorBarWidth - curve
						} v${rectNode.height + 0.2} h-${colorBarWidth - curve}q-${curve - 1} 0-${
							curve - 1
						}-${curve - 1}z`
					)
					.attr('fill', (d: NodeEntity) => d.data.color);

				// backdrop beside course card overlay
				const textBgWidth = rectNode.extWidth - rectNode.width - curve;
				nodeEnter
					.filter((d: any) => d.depth > 1 && !d.data.name.includes('Spacer'))
					.filter((d: any) => !d.data.isArchived)
					.append('path')
					.attr(
						'd',
						`M0 ${curve}q0-${curve} ${curve}-${curve}h${textBgWidth} v${rectNode.height} h-${textBgWidth}q-${curve} 0-${curve}-${curve}z`
					)
					.attr('fill', (d: any) => {
						const hasImage = disciplineData?.find(
							(discipline) => discipline.name === d.data.discipline
						)?.image;
						if (d.depth !== 0 && !hasImage) {
							return 'lightgray';
						}
						return 'rgba(0, 0, 0, 0.17)';
					});

				// discipline circle color indicator
				const centerPoint = rectNode.extWidth - rectNode.width;
				const dotRadius = 14;
				const divisionTextSize = 28;
				nodeEnter
					.filter((d: any) => d.depth > 1 && !d.data.name.includes('Spacer'))
					.filter((d: any) => !d.data.isArchived)
					.append('circle')
					.attr('cx', centerPoint / 2)
					.attr('cy', () => {
						const bottomPos = rectNode.height - dotRadius / 2;
						return bottomPos - divisionTextSize + 5;
					})
					.attr('fill', (d: any) => {
						const parentIdx = d.ancestors().length - 2;
						return d.ancestors()?.[parentIdx].data.color;
					})
					.attr('r', dotRadius);

				// course card division text indicator
				nodeEnter
					.filter((d: any) => d.depth > 1 && !d.data.name.includes('Spacer'))
					.filter((d: any) => !d.data.isArchived)
					.append('text')
					.attr('y', centerPoint / 2)
					.attr('x', centerPoint / 2)
					.attr('text-anchor', 'middle')
					.attr('dy', '.35em')
					.attr('fill', 'white')
					.style('font-size', `${divisionTextSize}px`)
					.style('dominant-baseline', 'middle')
					.style('text-shadow', '-2px 2px 6px rgba(0, 0, 0, .7)')
					.text((d: NodeEntity) => `${d.data.division?.split('')[0]?.toUpperCase()}S`);

				// course card course name
				const text = nodeEnter
					.filter((d: any) => !d.data.name.includes('Spacer'))
					.filter((d: any) => !d.data.isArchived)
					.append('text')
					.attr('y', centerPoint / 2)
					.attr('x', (d: NodeEntity) =>
						d.depth > 1 ? centerPoint + 10 : centerPoint - 10
					)
					.attr('dy', '.35em')
					.attr('fill', 'white')
					.style('font-size', (d: NodeEntity) => {
						if (d.depth === 0) {
							return '33px';
						}
						if (d.depth === 1) {
							return '24px';
						}
						if (d.data.name.split('').length >= 33) {
							return '18px';
						}
						return '21px';
					})
					.style('font-family', (d: NodeEntity) =>
						d.depth > 1 ? 'var(--font-regular)' : 'var(--font-semibold)'
					)
					.style('text-shadow', '-2px 2px 6px rgba(0, 0, 0, .7)')
					.text((d: NodeEntity) => {
						if (d.depth === 0) {
							return '';
						}
						return d.data.name;
					})
					.call(wrap, rectNode.width - 15);

				text.call(wrapDiscipline, rectNode.height);
				const nodeUpdate = nodeEnter.merge(node);

				// Transition to the proper position for the node
				nodeUpdate
					.lower()
					.conditionalTransition(isAdmin, duration)
					.attr('transform', function (d: NodeEntity) {
						// ?? what is causing this offset
						return `translate(${d.y},${d.x - rectNode.height / 2})`;
					})
					.attr('opacity', 1);
				// Remove any exiting nodes
				node.exit()
					.lower()
					.conditionalTransition(isAdmin, duration)
					.attr('transform', function () {
						// ?? what is causing this offset
						return `translate(${
							source.y
						},${(source?.x ?? 0) - rectNode.width / 4 - 6})`;
					})
					.attr('opacity', 0)
					.remove();

				// ?? add arrow symbol for courses
				// const sym = d3.symbol().type(d3.symbolTriangle).size(100);
				// nodeEnter
				// 	.append('path')
				// 	.attr('d', sym)
				// 	.attr('fill', (d: any) => {
				// 		if (d.depth === 0) {
				// 			return 'transparent';
				// 		}
				// 		return d.ancestors().at(-2).data.color;
				// 	})
				// 	.attr(
				// 		'transform',
				// 		`translate(-8, ${rectNode.height / 2}), rotate(90)`
				// 	);

				// ****************** links section ***************************
				// create multiple parent connections
				treeData.each((node: any) => {
					if (node.data.suppParent) {
						const parentIDArray = node.data.suppParent.map(
							(parent: any) => parent.tracker
						);
						node.altConnections = [];
						parentIDArray.forEach((id: string) => {
							const match = findMatchingNode(treeData, id);
							if (match) {
								node.altConnections.push(match);
							}
						});
						const currentIndex = node.ancestors().length - 2;
						const ancestorNode = node.ancestors()?.[currentIndex].data;

						treeData.each((innerNode: any) => {
							if (parentIDArray.includes(innerNode.data.tracker)) {
								multiParentNodes.push({
									id: node.data.id,
									parent: {
										x: node.x,
										y: node.y + rectNode.width,
									},
									y: innerNode.y + rectNode.width,
									x: innerNode.x,
									discipline:
										innerNode.parent.data.discipline ??
										innerNode.data.discipline ??
										node.data.discipline,
									name: node.data.name,
									color: ancestorNode.color,
								});
							}
						});
					}
				});

				// Update the links...
				// ID are used within transitions
				const link = g.selectAll('path.link').data(links, function (d: NodeEntity) {
					return `${d.data?.id}-${d.height}`;
				});

				const connectionLink = g
					.selectAll('path.multi-parent-link')
					.data(multiParentNodes, function () {
						return uuidv4();
					});

				const connectionEnter = connectionLink
					.enter()
					.insert('path', 'g')
					.attr('class', 'multi-parent-link link')
					.attr('stroke', (d: any) => d.color)
					.lower();

				const connectionUpdate = connectionEnter.merge(connectionLink);
				// Transition back to the parent element position
				connectionUpdate
					.lower()
					.conditionalTransition(isAdmin, duration * 1.5)
					.attr('opacity', 1)
					.attr('d', function (d: any) {
						return createCourseMapPaths(d);
					});

				// Remove any exiting links
				connectionLink
					.exit()
					.lower()
					.conditionalTransition(isAdmin, duration / 3)
					.attr('opacity', 0)
					.remove();

				// Enter any new links at the parent's previous position.
				const linkEnter = link
					.enter()
					.insert('path', 'g')
					.attr('class', 'link')
					.attr('stroke', (d: any) => {
						const parentIdx = d.ancestors().length - 2;
						return d.ancestors()?.[parentIdx].data.color;
					});

				// UPDATE
				const linkUpdate = linkEnter.merge(link);

				// Transition back to the parent element position
				linkUpdate
					.lower()
					.conditionalTransition(isAdmin, duration * 2.5)
					.attr('opacity', 1)
					.attr('d', (d: any) => {
						return diagonal(d, d.parent);
					})
					.on('start', (d: any, b: any, c: any) => {
						if (b === (c?.length ?? 0) - 1 && !isAdmin) {
							if (treeMap(root).descendants().length === 10) {
								zoom.current.transform(
									svg.interrupt().transition().duration(duration),
									d3.zoomIdentity
										.translate(clientWidth / 4.5, clientHeight / 2)
										.scale(0.6)
								);
								setTimeout(() => {
									document
										.querySelector('.initial-node')
										?.classList.add('stop-zoom');
								}, duration);
							} else {
								document
									.querySelector('.initial-node')
									?.classList.remove('stop-zoom');
							}
						}
					});

				// Remove any exiting links
				link.exit()
					.lower()
					.conditionalTransition(isAdmin, duration / 3)
					.attr('opacity', 0)
					.remove();

				// Store the old positions for transition.
				(nodes as NodeEntity[]).forEach((d: NodeEntity) => {
					d.x0 = d.x;
					d.y0 = d.y;
				});

				// ****************** edit icon section ***************************
				let editIcon = null;
				let mergeIcon = null;
				if (isAdmin) {
					// add circle for plus and minus icon
					editIcon = nodeEnter
						.append('circle')
						.attr('r', 13)
						.attr('cx', rectNode.extWidth)
						.attr('cy', rectNode.height / 2)
						.attr('class', (d: any) => {
							if (isAddingCourse) {
								return `map-plus-icon plus-icon-${d.data.tracker}-${d.depth}`;
							}
							// hide plus icon for discipline and root node
							if (
								(d.depth === 1 && d.data.isDiscipline && d.children?.length > 0) ||
								d.depth === 0
							) {
								return `base-node-icon`;
							}
							return 'map-minus-icon';
						});

					const moveChildrenArray = (arr: any, fromIndex: number, toIndex: number) => {
						const element = arr[fromIndex];
						arr.splice(fromIndex, 1);
						arr.splice(toIndex, 0, element);
					};

					// Move up icon
					nodeEnter
						.filter((d: any) => d.depth === 1)
						.append('circle')
						.style('cursor', 'pointer')
						.attr('r', 13)
						.attr('cx', -10)
						.attr('fill', 'var(--neutral-500)')
						.attr('cy', rectNode.height / 2 - 20)
						.on('click', (d: any, node: any) => {
							const idx = node.parent.children.findIndex(
								(x: any) => x.data.id === node.data.id
							);
							const idx2 = node.parent.data.children.findIndex(
								(x: any) => x.id === node.data.id
							);
							const tree = { ...node.parent };
							if (idx === 0) {
								moveChildrenArray(
									tree.children,
									idx,
									node.parent.children.length - 1
								);
								moveChildrenArray(
									tree.data.children,
									idx2,
									node.parent.children.length - 1
								);
							} else {
								moveChildrenArray(tree.children, idx, idx - 1);
								moveChildrenArray(tree.data.children, idx2, idx2 - 1);
							}

							updateTreeData(tree.data);
						});

					// Move down icon
					nodeEnter
						.filter((d: any) => d.depth === 1)
						.append('circle')
						.style('cursor', 'pointer')
						.attr('r', 13)
						.attr('cx', -10)
						.attr('fill', 'var(--neutral-500)')
						.attr('cy', rectNode.height / 2 + 20)
						.on('click', (d: any, node: any) => {
							const idx = node.parent.children.findIndex(
								(x: any) => x.data.id === node.data.id
							);
							const idx2 = node.parent.data.children.findIndex(
								(x: any) => x.id === node.data.id
							);
							const tree = { ...node.parent };

							if (idx === node.parent.children.length - 1) {
								moveChildrenArray(tree.children, idx, 0);
								moveChildrenArray(tree.data.children, idx2, 0);
							} else {
								moveChildrenArray(tree.children, idx, idx + 1);
								moveChildrenArray(tree.data.children, idx2, idx2 + 1);
							}

							updateTreeData(tree.data);
						});
					// move arrow down icon
					nodeEnter
						.append('path')
						.style('pointer-events', 'none')
						.attr('stroke', 'white')
						.attr('fill', 'white')
						.attr('transform', () => `translate(-22,64.5)`)
						.attr(
							'd',
							'M12 16a1 1 0 0 1-.7-.3l-6-6a1 1 0 0 1 1.4-1.4l5.3 5.3 5.3-5.3a1 1 0 0 1 1.4 1.4l-6 6a1 1 0 0 1-.7.3z'
						);
					// move up arrow icon
					nodeEnter
						.append('path')
						.style('pointer-events', 'none')
						.attr('stroke', 'white')
						.attr('fill', 'white')
						.attr('transform', () => `translate(-22,22.5)`)
						.attr(
							'd',
							'M18 16a1 1 0 0 1-.7-.3L12 10.4l-5.3 5.3a1 1 0 0 1-1.4-1.4l6-6a1 1 0 0 1 1.4 0l6 6A1 1 0 0 1 18 16z'
						);

					mergeIcon = nodeEnter
						.append('circle')
						.attr('r', 13)
						.attr('cx', 0)
						.attr('cy', 0)
						.attr('class', (d: any) => {
							const isMergeValid = d
								.ancestors()
								.filter(
									(c: any) =>
										c.data.discipline === d.data.discipline &&
										c.depth >= 1 &&
										c?.children?.length >= 1 &&
										c.data.tracker !== d.data.tracker
								)
								.some((c: any) => c?.children?.length > 1);

							if (isAddingCourse && isMergeValid) {
								return `map-merge-icon merge-icon-${d.data.tracker}-${d.depth}`;
							}
							const hasMultiChildren = d.ancestors().filter((a: any) => {
								if (a.children?.length >= 2) {
									return a;
								}
								return false;
							});
							const isOrphan = hasMultiChildren[0]?.depth === 0;
							if (isOrphan || d.parent?.parent?.children?.length === 1) {
								return 'empty-icon';
							}
							if (d.depth === 0) {
								return `base-node-icon`;
							}
							if (d.data.suppParent && d.data.suppParent?.length !== 0) {
								return 'map-minus-icon';
							}

							return 'empty-icon';
						});
				}

				createEditIcons({ isAdmin, isAddingCourse, rectNode, nodeEnter });

				if (isAdmin) {
					editIcon.on('click', (event: React.PointerEvent, sn: any) => {
						if (!isAdmin) return;
						if (isAddingCourse) {
							setParentMenuActive(false);
							setSelectedNode({ ...sn });
							const newNode = {
								name: '',
								children: [],
								new: false,
							};
							const className = (event.target as SVGElement).classList
								.toString()
								.replace(/\s/g, '.');
							const createdHierarchy: any = { ...d3.hierarchy(newNode) };
							createdHierarchy.depth = sn.depth + 1;
							createdHierarchy.height = sn.height - 1;
							createdHierarchy.parent = sn;
							createdHierarchy.id = '';
							createdHierarchy.data.new = true;
							setNewCourse({ ...createdHierarchy });
							setIsMenuActive(true);
							d3.select(`.${className}`).classed('active-icon', true);
						} else {
							if (sn.depth === 0) return;
							const idMatch = sn.id;
							const root = rootNode(sn) as any;
							if (sn.children !== undefined) {
								const idx = sn.parent.data.children.findIndex(
									(child: CourseNodeEntity) => child.id === idMatch
								);
								const newChildList = sn.parent.children.filter(
									(child: CourseNodeEntity) => child.id !== sn.id
								);
								const newDataList = sn.parent.data.children.filter(
									(child: CourseNodeEntity) => child.id !== sn.id
								);
								newChildList.splice(idx, 0, ...sn.children);
								newDataList.splice(idx, 0, ...sn.data.children);
								sn.parent.children = [...newChildList];
								sn.parent.data.children = [...newDataList];
								updateTreeData({ ...root?.data });
							} else {
								const newChildList = sn.parent.data.children.filter(
									(child: CourseNodeEntity) => child.id !== sn.id
								);
								sn.parent.data.children = newChildList;
								updateTreeData({ ...root?.data });
							}
						}
					});

					mergeIcon.on('click', (_: React.PointerEvent, sn: NodeEntity) => {
						if (!isAdmin) return;
						setSelectedNode(sn);
						setIsMenuActive(false);
						setParentMenuActive(true);
					});
				}
			}
		}

		if (isFetched) {
			renderMap();
		}
	}, [
		treeData,
		isAddingCourse,
		isAdmin,
		passedEvent,
		updateTreeData,
		isFetched,
		rootNode,
		isLoading,
		findMatchingNode,
		setCourseCoordsArray,
		duration,
		zoom,
		d3InstanceRef,
		disciplineData,
		siteSettings,
	]);

	const toggleAddingCourse = () => {
		setAddingCourse(!isAddingCourse);
	};

	if (isLoading) {
		return (
			<div style={{ margin: 'auto', height: '100%' }}>
				<Loader color="var(--org-color)" ringColor="var(--org-color)" />
			</div>
		);
	}

	return (
		<>
			<StyledMap id="course-map" ref={svgChartRef}>
				{!isAdmin && (
					<MapButtonControls
						zoom={zoom}
						svgInstance={d3InstanceRef}
						duration={duration / 2}
						zoomRef={zoomRef}
					/>
				)}
				{isAdmin && (
					<div style={{ alignSelf: 'flex-start' }}>
						{isMapAltered && (
							<FadeIn>
								<StyledNotification>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 486.5 486.5"
										width="20">
										<path d="M243.2 333.4c-13.6 0-25 11.4-25 25s11.4 25 25 25c13.1 0 25-11.4 24.4-24.4a24.4 24.4 0 00-24.4-25.6z" />
										<path d="M474.6 422a85.4 85.4 0 00.2-86.4L318.2 64.4a85 85 0 00-74.9-43.5 85.4 85.4 0 00-74.9 43.4L11.6 335.8a86 86 0 00.3 86.9 85.6 85.6 0 0074.7 42.9h312.8a86.1 86.1 0 0075.2-43.6zm-34-19.6a47.3 47.3 0 01-41.3 23.9H86.5a47.3 47.3 0 01-40.9-71.1L202.4 83.8a46.4 46.4 0 0141-23.7c17 0 32.3 8.9 40.8 23.8L441 355.3a46.7 46.7 0 01-.3 47z" />
										<path d="M237 157.9c-11.9 3.4-19.3 14.2-19.3 27.3l1.7 23.8 5.1 89.7a18.3 18.3 0 0018.7 17.6c10.2 0 18.2-8 18.7-18.2 0-6.2 0-12 .6-18.2l3.4-58c.6-12.4 1.7-25 2.3-37.4 0-4.5-.6-8.5-2.3-12.5a25 25 0 00-28.9-14.1z" />
									</svg>
									<span>Unsaved Changes</span>
								</StyledNotification>
							</FadeIn>
						)}
						<StyledActions>
							<StyledPrimaryButton
								className="save-btn"
								size="small"
								onClick={saveMapChanges}>
								Save Changes
							</StyledPrimaryButton>
							<SwitchToggle
								className="switch-toggle"
								passedEvent={toggleAddingCourse}
								isChecked={isAddingCourse}
								label="Add Course"
							/>
						</StyledActions>
						<StyledSecondaryButton
							className="menu-btn"
							size="small"
							onClick={() => {
								setSelectedNode(undefined);
								openPanel();
							}}>
							Open Course Panel
						</StyledSecondaryButton>
					</div>
				)}
				<svg ref={svgRef} />
				{isParentMenuActive && (
					<MergeCourseMapList
						isAddingCourse={isAddingCourse}
						selectedNode={selectedNode}
						isParentMenuActive={() => setParentMenuActive(false)}
						updateTreeData={updateTreeData}
					/>
				)}
				{!isAdmin && <PrivacyTagLine />}
			</StyledMap>
			<ToastNotification />
			{isConfirming && (
				<ConfirmModal
					modalActive={isConfirming}
					onConfirm={removeCourse}
					onCancel={() => setIsConfirming(false)}
					triggerModal={() => setIsConfirming(false)}
					message="Are you sure you want to delete this course?"
					selectedData={currentCourse?.course_name ?? ''}
				/>
			)}
			{isAdmin && isMenuActive && (
				<FadeIn>
					<CourseMapList
						deleteCourse={confirmDelete}
						passedEvent={openPanel}
						checkTreeDiff={checkTreeDiff}
						treeData={treeData}
						selectedNode={selectedNode}
						newCourse={newCourse}
						userRole={userRoleData?.user_role ?? ''}
						updateTreeData={updateTreeData}
					/>
				</FadeIn>
			)}
		</>
	);
};

export default CourseMap;
