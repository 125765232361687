import React, { useEffect, useState } from 'react';
import { StyledButton, StyledPrimaryButton } from '@/Shared/StyledElements';

interface SaveButtonProps {
	passedEvent: () => void;
	isSaving: number | boolean;
}

const SaveButton = ({ passedEvent, isSaving }: SaveButtonProps) => {
	const [saveState, setSaveState] = useState(true);
	useEffect(() => {
		const timer = setTimeout(() => setSaveState(!isSaving), 650);
		return () => clearTimeout(timer);
	});

	return (
		<>
			{saveState && (
				<StyledPrimaryButton type="submit" onClick={passedEvent}>
					Save
				</StyledPrimaryButton>
			)}
			{!saveState && (
				<StyledButton
					color="wine"
					type="button"
					disabled
					className="saving-btn"
					size="large">
					Saving...
				</StyledButton>
			)}
		</>
	);
};
export default SaveButton;
