import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { GlobalModalStyle } from '@/Shared/StyledElements';
import Loader from '@/components/Loader';
import { useCourseEditStore } from '@/stores/courseEditStore';
import CourseOutcomeTab from './CourseOutcomeTab/CourseOutcomeTab';
import { StyledCourseContainer, StyledFade, StyledFooterBody } from './CourseEdit.Styles';
import CourseHeader from './CourseHeader/CourseHeader';
import CourseEditContext from './CourseEditContext';
import Tabs from './Tabs';
import Panel from './Panel';
import CourseDescriptionTab from './CourseDescriptionTab/CourseDescriptionTab';
import CourseResourcesTab from './CourseResourcesTab/CourseResourcesTab';
import CourseUnitsTab from './CourseUnits/CourseUnitsTab';
import CourseComments from './CourseComments/CourseComments';
import CourseEditSubmit from './CourseEditSubmit/CourseEditSubmit';
import LessonPlansPanel from './CourseLessonPlansTab/LessonPlansPanel';
import CourseLessonPlans from './CourseLessonPlansTab/CourseLessonPlans';
import LessonPlanFooter from './CourseLessonPlansTab/LessonPlanFooter';
import CourseFooter from './CourseFooter/CourseFooter';

const CourseEdit = () => {
	const {
		canEdit,
		courseData,
		isAlignment,
		isApprovalMode,
		isFetched,
		isFetchedAfterMount,
		saveDraft,
		submitCourse,
	} = useContext(CourseEditContext);
	const { commentTypes } = useCourseEditStore();
	const { handleSubmit } = useForm();

	const onSubmit = () => {
		submitCourse();
	};

	const onDraftSubmit = () => {
		saveDraft();
	};

	if (!isFetched || !courseData || !isFetchedAfterMount) {
		return <Loader size="small" />;
	}

	return (
		<StyledFade>
			<>
				<GlobalModalStyle />
				<StyledCourseContainer>
					<div className="course-content">
						<CourseHeader />
						<Tabs selected={0}>
							<Panel title="Description">
								<CourseDescriptionTab />
							</Panel>
							<Panel title="Outcomes">
								<CourseOutcomeTab />
							</Panel>
							<Panel title="Resources">
								<CourseResourcesTab />
							</Panel>
							<Panel title="Units of Focus">
								<CourseUnitsTab />
							</Panel>
							<>
								{!isApprovalMode && (
									<Panel title="Lesson Plans">
										<CourseLessonPlans />
									</Panel>
								)}
							</>
						</Tabs>
					</div>
					<div className="comment-panel">
						{!commentTypes.includes('none') && <CourseComments />}
						{commentTypes.includes('none') && <LessonPlansPanel />}
					</div>
				</StyledCourseContainer>
				<StyledFooterBody>
					{canEdit &&
						!isApprovalMode &&
						!isAlignment &&
						!commentTypes.includes('none') && (
							<CourseEditSubmit
								submitEvent={handleSubmit(onSubmit)}
								draftSubmitEvent={handleSubmit(onDraftSubmit)}
							/>
						)}
					{isApprovalMode && !commentTypes.includes('none') && <CourseFooter />}
					{commentTypes.includes('none') && <LessonPlanFooter />}
				</StyledFooterBody>
			</>
		</StyledFade>
	);
};

export default CourseEdit;
