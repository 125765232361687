import { useContext, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { StyledSecondaryButton } from '@/Shared/StyledElements';
import { EditorType } from '@/Enums/enum';
import { useCourseEditStore } from '@/stores/courseEditStore';
import FadeIn from '@/components/animations/FadeIn';
import CourseEditContext from '../CourseEditContext';
import CourseUnitsForm from './CourseUnitsForm';
import CourseUnitsPlaceholder from './CourseUnitsPlaceholder';
import ApprovalCommentForm from '../CourseDescriptionTab/ApprovalCommentForm';

const CourseUnitsTab = () => {
	const {
		addFocus,
		updateFocus,
		submitCourse,
		canEdit,
		courseDraftData,
		courseData,
		isApprovalMode,
	} = useContext(CourseEditContext);
	const { courses_focuses: coursesDraftFocuses } = courseDraftData;
	const { handleSubmit, register, getValues, watch } = useForm({
		shouldUnregister: true,
	});
	const inputFocusRef = useRef(false);

	const setNewFocus = () => {
		addFocus();
		inputFocusRef.current = true;
	};

	const onSubmit = () => submitCourse();

	const handleFocusUpdate = () => {
		const watchAllFields = watch();
		const formEntires = Object.entries(watchAllFields);
		const isType = (val: string, comp: string) => val.includes(comp);
		const isEnd = (index: number) => index === formEntires.length - 1;
		let sub = {
			courses_focuses_id: '',
			focus: {
				focus_order: 0,
				focus_type: '',
				focus_id: '',
				focus_title: '',
				focuses_skills: [] as FocusSkillEntity[],
			},
		};
		const skillArray: any = [];
		const focusArray: any = [];
		formEntires.forEach((entry: string[], idx: number) => {
			const [prop, val] = entry;
			if (isType(prop, 'focusBlob')) {
				const { courseFocusId, focusId, focusOrder } = JSON.parse(val);
				// get title from the coursesDraftFocuses because value is not tracked
				// using react-hook-form
				const title =
					coursesDraftFocuses?.find((focus) => focus.courses_focuses_id === courseFocusId)
						?.focus?.focus_title ?? '';
				sub.courses_focuses_id = courseFocusId;
				sub.focus.focus_id = focusId;
				sub.focus.focus_title = title;
				sub.focus.focus_order = +focusOrder;
				sub.focus.focus_type = '';
				focusArray.push(sub);

				sub = {
					courses_focuses_id: '',
					focus: {
						focus_order: 0,
						focus_type: '',
						focus_id: '',
						focus_title: title,
						focuses_skills: [] as FocusSkillEntity[],
					},
				};
			}

			if (isType(prop, 'skillBlob')) {
				const { focusSkillId, skillText, skillId, skillType, parentId } = JSON.parse(val);
				const skills = {
					parentId,
					skill: {
						focuses_skills_id: focusSkillId,
						skill: {
							skill_id: skillId,
							skill_text: getValues(skillText),
							skill_type: getValues(skillType),
						},
					},
				};
				skillArray.push(skills);
			}

			if (isEnd(idx)) {
				focusArray.forEach((focus: any) => {
					skillArray.forEach((skill: any) => {
						if (skill.parentId === focus.courses_focuses_id) {
							focus.focus.focuses_skills.push(skill.skill);
						}
					});
				});
			}
		});

		updateFocus(focusArray);
	};

	const { updateCommentTypes } = useCourseEditStore();
	useEffect(() => {
		updateCommentTypes([EditorType.UNITS]);
	}, [updateCommentTypes]);

	return (
		<FadeIn style={{ width: '100%' }}>
			<>
				{isApprovalMode && (
					<ApprovalCommentForm courseId={courseData.course_id} type={EditorType.UNITS} />
				)}
				<div style={{ width: '100%' }}>
					<CourseUnitsPlaceholder
						isApprovalMode={isApprovalMode}
						coursesDraftFocuses={coursesDraftFocuses ?? []}
					/>
					{/* // TODO: remove form from here - it causes error due to nesting */}
					<form onSubmit={handleSubmit(onSubmit)} onChange={handleFocusUpdate}>
						<CourseUnitsForm inputFocusRef={inputFocusRef} register={register} />
					</form>
				</div>
				<>
					{canEdit && !isApprovalMode && (
						<>
							<div className="actions-container">
								<StyledSecondaryButton
									size="small"
									type="button"
									onClick={() => setNewFocus()}>
									Add Unit of Focus
								</StyledSecondaryButton>
							</div>
						</>
					)}
				</>
			</>
		</FadeIn>
	);
};

export default CourseUnitsTab;
