import { useEffect } from 'react';

const useSaveDraft = (
	isTriggered: boolean,
	callback: () => unknown,
	resetStateCallback: React.Dispatch<React.SetStateAction<boolean>>
) => {
	useEffect(() => {
		if (isTriggered) {
			callback();
			resetStateCallback(false);
		}
	}, [isTriggered, callback, resetStateCallback]);
};

export default useSaveDraft;
