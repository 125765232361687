import { useContext } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import { StyledPrimaryButton, StyledSecondaryButton } from '@/Shared/StyledElements';
import CourseEditContext from '../CourseEditContext';

interface CourseModalSubmitProps {
	submitEvent(): void;
	draftSubmitEvent(): void;
}

const CourseEditSubmit = ({ submitEvent, draftSubmitEvent }: CourseModalSubmitProps) => {
	const isMutatingPosts = useIsMutating({ mutationKey: ['draft-status'] });
	const { isPendingApproval } = useContext(CourseEditContext);

	return (
		<>
			<StyledSecondaryButton
				type="submit"
				disabled={isMutatingPosts !== 0 || isPendingApproval()}
				onClick={draftSubmitEvent}
				className="draft-btn">
				Save Draft
			</StyledSecondaryButton>
			<StyledPrimaryButton
				type="submit"
				disabled={isMutatingPosts !== 0}
				onClick={submitEvent}
				className="save-btn">
				Submit for Review
			</StyledPrimaryButton>
		</>
	);
};

export default CourseEditSubmit;
