/* eslint-disable array-callback-return */
import { useCallback, useContext } from 'react';

import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { PDFIcon } from '@/icons/index';
import { SetStatus } from '@/Enums/enum';
import Badge from '@/components/Badge/Badge';
import { StyledH3 } from '@/Shared/Typography/typography';
import { StyledSecondaryButton } from '@/Shared/StyledElements';
import { getFormattedDate } from '@/utils';
import GlobalContext from '@/context/GlobalContext';
import CourseEditContext from '../CourseEditContext';
import { StyledCourseHeader, StyleDownloadButton } from './CourseHeader.Styles';
import { CoursePdf, downLoadPDF } from './CoursePdf';

const CourseHeader = () => {
	const { isApprovalMode, courseData, courseDraftData } = useContext(CourseEditContext);
	const { siteSettings } = useContext(GlobalContext);
	const {
		is_saved_draft: isSavedDraft,
		course_name: courseName,
		last_updated: lastUpdated,
		course_id: courseId,
		submission_id: submissionId,
		submission: courseSubmission,
		is_archived: isArchived,
	} = courseData;
	const navigate = useNavigate();
	const { pathname, hash } = useLocation();

	const isPendingApproval = useCallback(() => {
		if (courseSubmission) {
			return Object.values(courseSubmission).every((status) => status !== SetStatus.APPROVED);
		}
		return false;
	}, [courseSubmission]);

	const renderBadge = () => {
		if (isSavedDraft && !isApprovalMode) {
			return <Badge text="Draft" style={{ marginRight: 'var(--spacing-4)' }} />;
		}

		if (isPendingApproval() && !isApprovalMode) {
			return <Badge text="Approval Pending" style={{ marginRight: 'var(--spacing-4)' }} />;
		}
		return null;
	};

	const navigateBack = () => {
		if (pathname.includes('submissions')) {
			navigate('/dashboard/submissions', {
				state: {
					id: submissionId,
				},
			});
			return;
		}
		// add search param of showArchived and courseId to state
		// courseId allows page to scroll to previously selected course
		navigate(
			{
				pathname: '/dashboard/courses',
				search: createSearchParams({ showArchived: `${isArchived}` }).toString(),
			},
			{
				state: {
					id: courseId,
				},
			}
		);
	};

	return (
		<StyledCourseHeader>
			<StyledSecondaryButton size="small" color="wine" onClick={navigateBack}>
				Back
			</StyledSecondaryButton>
			{!isApprovalMode && !hash.includes('lesson-plans') && (
				<StyleDownloadButton
					size="small"
					color="wine"
					onClick={() => downLoadPDF(siteSettings?.logo ?? '')}>
					<div className="label">Download</div>
					<div className="icon">
						<PDFIcon />
					</div>
				</StyleDownloadButton>
			)}
			<div className="title">
				<div>
					<StyledH3 mb="8px" mr="16px">
						{courseName}
					</StyledH3>
				</div>
				<div>
					{isArchived ? (
						<Badge
							text="Archived"
							style={{ marginRight: 'var(--spacing-4)' }}
							color="var(--yellow-200)"
						/>
					) : null}

					{renderBadge()}
				</div>
				{lastUpdated && (
					<time className="date" dateTime={lastUpdated}>
						Last Updated {getFormattedDate(lastUpdated)}
					</time>
				)}
			</div>
			{!isApprovalMode && (
				<CoursePdf courseDraftData={courseDraftData} courseName={courseData.course_name} />
			)}
		</StyledCourseHeader>
	);
};
export default CourseHeader;
