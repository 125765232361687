import styled from 'styled-components';

export const StyledWrapper = styled.div`
	align-items: baseline;
	display: flex;
	margin-left: var(--spacing-4);
	margin-right: var(--spacing-4);

	.select-wrapper {
		width: 180px;

		button {
			&:hover,
			&:focus {
				background-color: var(--org-color);
			}
		}
	}

	.selected {
		white-space: nowrap;
	}
`;

export const StyledAutoComplete = styled.div`
	flex-shrink: 0;
	margin-right: var(--spacing-4);
	position: relative;
	width: 200px;

	input {
		border-radius: 6px;
		border: 1px solid var(--input-border);
		padding: var(--spacing-2) var(--spacing-4);
		width: 100%;

		&:focus-visible {
			outline-color: var(--elevate-primary);
		}
	}

	.suggestions,
	.no-suggestions {
		background-color: var(--c-white);
		border-radius: 6px;
		border: 1px solid #e5e7e8;
		cursor: pointer;
		margin-top: 1px;
		max-height: 250px;
		overflow: auto;
		padding: 0;
		position: absolute;
		width: 100%;
	}

	.suggestion-active {
		background-color: var(--input-border);
	}

	.no-suggestions {
		padding: var(--spacing-2) var(--spacing-4);
	}

	li {
		color: var(--label-text);
		padding: var(--spacing-2) var(--spacing-4);

		&:hover {
			background-color: var(--input-border);
		}
	}

	button {
		border-radius: 50%;
		border: none;
		cursor: pointer;
		line-height: 0;
		padding: 5px;
		position: absolute;
		margin-left: -24px;
		top: 12px;

		&:hover,
		&:focus {
			background-color: var(--org-color);
			color: var(--neutral-50);
			outline: none;
		}
	}
`;
